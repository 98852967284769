import React from "react"
import Register from "../components/Register"
class RegisterPage extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }
  }
  render(){
    return(
      <Register method="post"/>
      )
  }
}

export default RegisterPage