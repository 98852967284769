import React from 'react';
import { projects } from "../../actions";
import { connect } from "react-redux";
import { SelectBox } from "../../components/SelectBox"

import { Page, Grid, GalleryCard, Form, Button, Dimmer, Header } from "tabler-react";
import Pagination from "react-js-pagination";
import '../../App.css'
import './ipixel.css'
class Inspection_ipixel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '검수 미완료',
            objectType: [],
            category: '',
            images: [],
            total_images: '',
            labeled_images: '',
            total_approved: '',
            total_pages: 1,
            current_page: 1,
            page: 1,
            view: false
        }
    }

    onChange(e) {
        this.setState({
            filter: e.target.value
        })
    }

    handlePageChange = async (e) => {
        await this.setState({
            page: e
        })
        await this.search()
    }
    componentDidMount() {
        this.props.get_object_type().then(response =>
            this.setState({
                objectType: response
            })
        );
    }
    get_object_type = (val) => {
        if (this.state.page !== 1)
            alert("카테고리 변경")

        this.setState({
            category: val,
        })
    };

    //검색 아이콘 눌렀을시 함수
    search = () => {
        this.setState({
            view: false
        })
        let { category, filter, page } = this.state
        const is_valid_image = "t"
        if (category === '') {
            category = this.state.objectType[0].title
        }
        //filter, category page를 파라미터로 넘겨주고 검색
        //console.log(this.props.lists);

        this.props.get_images_for_inspection(filter, category, page, is_valid_image).then(res => {
            //검색한 데이터에 id값을 가져와서 data state에 저장해줌
            console.log(res)
            const data = res.image_data.map(data =>
                data.id
            );
            this.setState({
                view: true,
                data: data,
                images: res.image_data,
                total_images: res.total_images,
                labeled_images: res.labeled_images,
                total_approved: res.total_approved,
                total_pages: res.total_pages
            })
        }
        );
        //console.log(this.state.images)
    };

    // _return_paging = () => {
    //     let table = [];
    //     for (let i = 0; i < 3; i++) {
    //         console.log(i, 'aaaaaaaaaaaa')
    //     }
    //     return table
    // };

    render() {
        const options = (
            <React.Fragment>
                <Form.Group className="m-1" label="정렬 기준">
                    <Form.Select value={this.state.filter} onChange={this.onChange.bind(this)}>
                        <option value="작업 날짜 (오래된 순)">작업 날짜 (오래된 순)</option>
                        <option value="검수 미완료">검수 미완료</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="m-1" label="카테고리">
                    <Grid.Row gutters="xs">
                        <Grid.Col>
                            {
                                this.state.objectType ?
                                    <SelectBox sendData={this.get_object_type} data={this.state.objectType} />
                                    : null
                            }
                        </Grid.Col>
                        <Grid.Col auto>
                            <Button
                                onClick={this.search}
                                color="secondary"
                                icon="search"
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Form.Group>
            </React.Fragment>
        );

        return (
            <Page.Content>
                <Page.Header
                    title="검수페이지"
                    //subTitle={`${this.state.data.length}/${this.state.images.length}`}
                    options={options}
                />
                <Grid.Row card>
                    {this.state.view ?
                        this.state.images.map((image, key) => (
                            <Grid.Col xl={2} sm={6} md={3} key={key}>
                                <GalleryCard>
                                    <GalleryCard.Image
                                        href={image.id}
                                        className={"inspectionCard"}
                                        src={'https://datarepublic-upload-files.s3.ap-northeast-2.amazonaws.com/' + image.path}
                                        alt={image.id}
                                    />
                                    <GalleryCard.Footer>
                                        <GalleryCard.Details
                                            //avatarURL={"#FFFFFF"}
                                            fullName={image.approved ?
                                                <Header.H4 className={"text-success"}>검수완료</Header.H4>
                                                : <Header.H4 className={"text-danger"}>검수 미완료</Header.H4>}
                                        //dateString={image.id}
                                        />
                                    </GalleryCard.Footer>
                                </GalleryCard>
                            </Grid.Col>
                        )) : null
                    }
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col className={"text-center"}>
                        <Pagination
                            activePage={this.state.page}
                            itemsCountPerPage={1}
                            totalItemsCount={this.state.total_pages}
                            pageRangeDisplayed={5}
                            onChange={e => this.handlePageChange(e)}
                        />
                    </Grid.Col>
                </Grid.Row>
                {/* <div className={'row'}>
                    {this._return_paging()}
                    <button className={'btn btn-info'}>{this.state.current_page}</button>
                    <div className={'ml-5'}>총 페이지 {this.state.total_pages}</div>
                </div> */}
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_object_type: () => {
            return dispatch(projects.get_object_type())
        },
        get_images_for_inspection: (filter, category, page, is_valid_image) => {
            return dispatch(projects.get_images_for_inspection(filter, category, page, is_valid_image))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Inspection_ipixel);