import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { RouterContextProvider, Site, } from "tabler-react";
import { auth } from "../actions";
import { connect } from "react-redux";

const navBarItems: Array<navItem> = [
    {
        value: "홈페이지",
        to: "/",
        icon: "home",
        LinkComponent: withRouter(NavLink),
    },
    {
        value: "거래처 목록",
        to: "/projects/",
        icon: "briefcase",
    },
];

const navBarItems2: Array<navItem> = [
    {
        value: "홈페이지",
        to: "/",
        icon: "home",
        LinkComponent: withRouter(NavLink),
    },
    {
        value: "프로젝트",
        to: "/projects/",
        icon: "briefcase",
        LinkComponent: withRouter(NavLink),
    },
];


class SiteWrapper extends React.Component {


    render() {
        const accountDropdownProps = {
            avatarURL: this.props.auth.user.img,
            name: this.props.auth.user.name,
            description: this.props.auth.user.is_manager ? '관리자' : '일반 회원',
            options: [
                { icon: "user", value: "프로필", to: "/profile/" },
                { icon: "log-out", value: "로그아웃", to: "/logout/" },
                // { icon: "settings", value: "Settings" },
                // { icon: "mail", value: "Inbox", badge: "6" },
                // { icon: "send", value: "Message" },
                // { isDivider: true },
                // { icon: "help-circle", value: "Need help?" },
            ],
        };

        return (
            <Site.Wrapper
                headerProps={{
                    href: "/",
                    alt: "데이터리퍼블릭",
                    imageURL: "https://cdn.iconscout.com/icon/premium/png-256-thumb/ai-75-806497.png",
                    accountDropdown: accountDropdownProps,
                }}
                navProps={{ itemsObjects: this.props.auth.is_manager ? navBarItems : navBarItems2 }}
                routerContextComponentType={withRouter(RouterContextProvider)}
                footerProps={{
                    links: [],
                    note:
                        "",
                    copyright: (
                        <React.Fragment>
                            Copyright © 2019
                            <a href="#"> Datarepublic</a>
                        </React.Fragment>
                    ),
                    nav: (
                        <React.Fragment>

                        </React.Fragment>
                    ),
                }}
            >
                {this.props.children}
            </Site.Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => {
            return dispatch(auth.loadUser());
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SiteWrapper);
