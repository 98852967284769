import React from "react";
import { Page, Grid, Form, Card, Button } from "tabler-react"
import PostCode from "../../components/PostCode"
import { auth } from "../../actions";
import { connect } from "react-redux";
import '../../App.css'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        username: "username",
        //password: "",
        name: "name",
        birth: "birth",
        email: "email",
        phone: "phone",
        gender: "gender",
        address1: "address1",
        address2: "address2",
        foreign_language: [],
        english: false,
        chinese: false,
        japanese: false,
        bank_name: "",
        bank_account: "",
        social_security_number1: 0,
        social_security_number2: 0
        //confirmPassword:"-",
      }
    }
  }

  componentDidMount() {
    this.props.user_profile().then(res => {
      console.log(res)
      this.setState({
        ...this.state,
        user: res
      })
    })
  }

  checkEmail(str) {
    let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (regExp.test(str)) {
      return true
    } else return false;
  }

  handleForeignLanguageInput(event) {
    const {user} = this.state
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    user[name] = value
    this.setState({
      user
    });
  }
  checkRegisterInfo = () => {
    const {user} = this.state
    if (user.email.length === 0) {
      alert('이메일을 입력해주세요');
      return false
    } else {
      if (!this.checkEmail(user.email)) {
        console.log(user.email, this.checkEmail(user.email))
        alert('올바른 이메일 주소를 입력해주세요');
        return false
      }
    }
    // 연락처 입력 여부
    if (user.phone.length === 0) {
      alert('연락처를 입력해주세요');
      return false
    }

    // 성별 입력 여부
    if (user.gender === "") {
      alert('성별을 입력해주세요');
      return false
    }

    // 주소 입력
    if (user.address1.length === 0 || user.address2.length === 0) {
      alert('주소를 입력해주세요');
      return false
    }
    let foreign_language_boolean = [user.english, user.chinese, user.japanese];
    let foreign_language_field = ['영어', '중국어', '일본어'];
    let foreign_language = [];
    foreign_language_boolean.map((item, id) => {
      if (item) {
        foreign_language.push(foreign_language_field[id])
      }
    }
    );
    user.foreign_language = foreign_language
    this.setState({user})
    return true
  }
  onSubmit = async (e) => {
    e.preventDefault()
    const {user} = this.state
    let infoValid = await this.checkRegisterInfo();
    if (infoValid) {
      console.log(user)
      // let info = {
      //   email: user.email,
      //   phone: user.phone,
      //   address1: user.address1,
      //   address2: user.address2,
      //   foreign_language: user.foreign_language,
      //   bank: user.bank,
      //   accoutNumber: user.accoutNumber,
      //   registration_number_first: user.registration_number_first,
      //   registration_number_last: user.registration_number_last
      // }
      //await this.props.editProfile(info);
      //window.location = '/'
    } else {
      // alert('가입 불가')
    }
  }
  //value이랑 data를 받아오면 user의 data에 해당하는 value를 변경해줍니다.
  //ex) value = 5 data = "address", user.address = 5로 변경
  changeUserData(value, data){
    const {user} = this.state
    user[data] = value
    this.setState({
      user
    })
  }
  render() {
    const {user} = this.state
    return (
      <Page.Content title="프로필">
        <Form className="card" onSubmit={this.onSubmit}>
          <Card.Body>
            <Card.Title>프로필 수정</Card.Title>
            <Grid.Row>
              <Grid.Col sm={12} md={4}>
                <Form.Group>
                  <Form.Label>아이디</Form.Label>
                  <Form.StaticText>
                    {user.username}
                  </Form.StaticText>
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} md={4}>
                <Form.Group>
                  <Form.Label>이름</Form.Label>
                  <Form.StaticText>
                    {user.name}
                  </Form.StaticText>
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} md={4}>
                <Form.Group>
                  <Form.Label>생년월일 / 성별</Form.Label>
                  <Form.StaticText>
                    {user.birth} / {user.gender}
                  </Form.StaticText>
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} md={4}>
                {/* <Form.Group>
                   <Form.FieldSet>
                      <Form.Group label="비밀번호 변경">
                      <Form.InputGroup>
                        <Form.Input
                          onChange={e=>this.setState({password: e.target.value})}
                          placeholder="현재 비밀번호"
                          type="password"
                          />
                        <Form.InputGroupAppend>
                          <Button
                            RootComponent="a"
                            color="primary"
                            href="http://www.google.com"
                          >
                            확인
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                            onChange={e=>this.setState({password: e.target.value})}
                            type="password"
                            placeholder="비밀번호"
                          />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                            tick={this.state.password===this.state.confirmPassword}
                            cross={this.state.password!==this.state.confirmPassword}
                            onChange={e=>this.setState({confirmPassword: e.target.value})}
                            type="password"
                            placeholder="비밀번호 확인"
                            />
                      </Form.Group>
                    </Form.FieldSet>
                  </Form.Group> */}
                <Form.Group>
                  <Form.Label>주민등록번호</Form.Label>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Input
                        onChange={e => this.changeUserData(e.target.value, "social_security_number1")}
                        type="number"
                        placeholder="앞자리를 입력해주세요"
                        tick={`${user.social_security_number1}`.length > 5 && `${user.social_security_number1}`.length <= 6}
                        cross={`${user.social_security_number1}`.length < 6 || `${user.social_security_number1}`.length > 6}
                        value={user.social_security_number1}
                      />
                    </Grid.Col>
                    -
                  <Grid.Col>
                      <Form.Input
                        onChange={e => this.changeUserData(e.target.value, "social_security_number2")}
                        type="number"
                        className="numberPassword"
                        placeholder="뒷자리를 입력해주세요"
                        tick={`${user.social_security_number2}`.length > 6 && `${user.social_security_number2}`.length <= 7}
                        cross={`${user.social_security_number2}`.length < 7 || `${user.social_security_number2}`.length > 7}
                        value={user.social_security_number2}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Form.StaticText>주민등록번호가 잘못 입력되면 현금 지급이 블가능합니다.</Form.StaticText>
                </Form.Group>
                <Form.Group>
                  <Form.Label>전화번호</Form.Label>
                  <Form.Input
                    onChange={e => this.changeUserData(e.target.value, "phone")}
                    placeholder="연락처"
                    value={user.phone}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>이메일</Form.Label>
                  <Form.Input
                    onChange={e => this.changeUserData(e.target.value, "email")}
                    type="email"
                    value={user.email}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} md={4}>
                <PostCode getAddress={address=>this.changeUserData(address, "address1")} />
              </Grid.Col>
              <Grid.Col sm={12} md={4}>
                <Form.Group>
                  <Form.Label>주소</Form.Label>
                  <Form.Input
                    readOnly
                    value={user.address1}
                    placeholder="주소를 선택해주세요"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>상세 주소</Form.Label>
                  <Form.Input
                    // onChange={e => this.setState({ address2: e.target.value })}
                    onChange={e=>this.changeUserData(e.target.value, "address2")}
                    type="text"
                    placeholder="Home Address"
                    value={user.address2}
                  />
                </Form.Group>
                <Form.Group>
                  <form className={'form-group'}>
                    <label>영어</label>
                    <input className={'m-2'} name={'english'} type={'checkbox'} checked={user.english}
                      onChange={e=>this.handleForeignLanguageInput(e)} />
                    <label>중국어</label>
                    <input className={'m-2'} name={'chinese'} type={'checkbox'} checked={user.chinese}
                      onChange={e=>this.handleForeignLanguageInput(e)} />
                    <label>일본어</label>
                    <input className={'m-2'} name={'japanese'} type={'checkbox'} checked={user.japanese}
                      onChange={e=>this.handleForeignLanguageInput(e)} />
                  </form>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Body>
            <Card.Title>계좌 정보</Card.Title>
            <Grid.Row>
              <Grid.Col sm={12}>
                <Form.Group label="은행" md={4}>
                  <Form.Select onChange={e => this.changeUserData(e.target.value, "bank_name")}>
                    <option>
                      은행1
                      </option>
                    <option>
                      은행2
                      </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group label="예금주명">
                  <Form.StaticText>{user.name}</Form.StaticText>
                </Form.Group>
                <Form.Group label="계좌번호">
                  <Form.Input
                    onChange={e => this.changeUserData(e.target.value, "bank_account")}
                    type="number"
                    placeholder="숫자만 입력해주세요"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12}>
                <Form.StaticText>본인 명의 계좌만 신청 가능합니다.</Form.StaticText>
                <Form.StaticText>계좌번호 입력 실수, 계좌주 오류 등으로 인한 사고는 책임지지 않습니다.</Form.StaticText>
                <Form.StaticText>보통예금계좌만 등록해주세요. (CMA, 가상계좌, 적금 등은 이체 불가)</Form.StaticText>
                <Form.StaticText>농협의 경우 단위농협과 농협중앙회를 확인하시고 입력해주세요. (농협고객센터 1588-2100)</Form.StaticText>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer className="text-right">
            <Button type="submit" color="primary">
              수정 완료
              </Button>
          </Card.Footer>
        </Form>
      </Page.Content>
    )
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return { field, message: state.auth.errors[field] };
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    user_profile: () =>
      dispatch(auth.user_profile()),
    editProfile: (info) =>
      dispatch(auth.editProfile(info)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);