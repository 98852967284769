import React from 'react';
import { connect } from "react-redux";
import { projects } from "../../actions";
import Cropper from "react-cropper";
import CropCard from "../../components/CropCard";
import { Link } from "react-router-dom";
import ScrollArea from "react-scrollbar";
import { Grid, Card, Form, Page, Button } from "tabler-react"

class Return_ipixel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image_path: '',
            answers: [],
            image_label_list: [{ id: '', answer: '' }],
            showModify: false,
            fixAnswer: '',
            survey: [],
            answer_id: '',
            selected_image: '',
            selected_survey: [],
            modifySurvey: false,
            msg: '',
            next: ''
        }
    }


    componentDidMount() {
        this.props.get_return_images_for_detail(this.props.match.params.inspection_id).then(
            res => {
                this.setState({
                    image_path: 'https://datarepublic-upload-files.s3.ap-northeast-2.amazonaws.com/' + res.image_path,
                    image_label_list: res.image_label_list,
                    survey: res.survey,
                    answer_id: res.answer_id,
                    msg: res.msg
                });
                console.log(res)
            }
        )

    }

    _crop() {
    }


    _click(id) {
        const result = this.state.image_label_list[id]
        const survey = this.state.survey[id]
        console.log(survey)
        this.refs.cropper.setData(
            result
        );
        this.setState({
            selected_survey: survey,
            showModify: true,
            selected_image: result.id
        })
    };

    _changeAnswer2 = () => {
        let imageLabel = this.refs.cropper.getData();
        let fixAnswer = this.state.fixAnswer;
        let answer_id = this.state.answer_id;
        let image_id = this.state.selected_image;
        let data = {
            imageLabel: imageLabel,
            fixAnswer: fixAnswer,
            answer_id: answer_id,
            image_id: image_id,
            type: 'change'
        };
        console.log(data);
        this.props.change_images_for_inspection_detail(data)
        window.location.reload()
    };

    _deleteAnswer2 = () => {
        let image_id = this.state.selected_image;
        let data = {
            image_id: image_id,
            type: 'delete'
        };
        this.props.change_images_for_inspection_detail(data)
        window.location.reload()
    };


    _changeSurveyOption(e, survey_id) {
        // console.log(e.target.value, survey_id)
        let data = {
            survey_id: survey_id,
            answer: e.target.value,
            type: 'change_survey'
        };
        this.props.change_survey_for_inspection_detail(data)
    }

    _handleAdd = () => {
        let answers = this.state.answers;
        let questionAnswer = this.state.questionAnswer;
        let answer = {
            'data': this.refs.cropper.getData(),
            'img': this.refs.cropper.getCroppedCanvas().toDataURL(),
            'questionAnswer': questionAnswer,

        };
        answers.push(answer);
        this.setState({
            answers: answers,
        });
        console.log(answer)
    };

    _deleteAnswer(item) {
        this.setState(prevState => ({
            answers: prevState.answers.filter(el => el !== item)
        }));
    };

    _changeAnswer(item) {
        let answers = this.state.answers;
        let selectedAnswer = answers.filter(el => el === item);
        this.setState({
            showChange: true,
            selectedChangeAnswer: selectedAnswer[0],
        });
    };

    _unableToAnswer = () => {
        let data = {
            type: '작업 불가',
            answer_id: this.state.answer_id
        };
        this.props.change_images_for_inspection_detail(data).then(res =>
        // console.log(res)
        { window.location.replace(res.next) }
        )

    };

    _handleSubmit = () => {
        let answers = this.state.answers;
        answers.map((data) => data.img = '');
        let data = {
            // survey: this.props.form.survey,
            answers: answers,
            img_id: this.props.match.params.inspection_id,
            type: 'add_image',
            answer_id: this.state.answer_id
        };
        console.log(data);
        this.props.change_images_for_inspection_detail(data)
        // this.props.submit_image_multiple_choice_project(data);
        // // this._search();
        // this.setState({
        //     answers: [],
        //     add_text_input: "",
        //     selectedChangeAnswer: '',
        //     change_text_input: ''
        // })
        window.location.reload()
    };

    _approve = () => {
        let data = {
            type: 'approve',
            answer_id: this.state.answer_id
        };
        this.props.change_images_for_inspection_detail(data).then(res =>
        // console.log(res)
        { window.location.replace(res.next) }
        )

    };


    render() {
        const { selected_survey } = this.state

        let itemElements = []

        this.state.answers.map((item, index) =>
            itemElements.push(
                <div key={index} className="ml-5" style={{ width: '13rem', display: "inline-block" }}>
                    <Button.List>
                        <Button color="success" onClick={() => this._changeAnswer(item)}>수정</Button>
                        <Button color="danger" onClick={() => this._deleteAnswer(item)}>삭제</Button>
                    </Button.List>
                    <CropCard style={{ marginTop: 0 }} src={item.img} alt={index} answer={item.answer}
                        labeling={item.labeling} />
                </div>
            )
        );

        itemElements.reverse();


        return (

            <Page.Content title="반려 상세 페이지">
                <Card>
                    <Card.Body>
                        {
                            this.state.msg === 'no label' ? <div>작업이 없습니다.</div> :
                                <div className='m-auto'>
                                    {
                                        this.state.image_path ?
                                            <div>
                                                <Grid.Row>
                                                    <Grid.Col lg={6} sm={12}>
                                                        <Cropper
                                                            autoCropArea={1}
                                                            className="img-container"
                                                            ref='cropper'
                                                            style={{ height: '100%' }}
                                                            preview='.preview'
                                                            src={this.state.image_path}
                                                        //crop={this._crop.bind(this)}
                                                        />
                                                    </Grid.Col>
                                                    <Grid.Col>
                                                        {
                                                            selected_survey &&
                                                            selected_survey.map((data, key) =>
                                                                <Form.Group label={data.question}>
                                                                    <Form.Radio
                                                                        isInline
                                                                        value="예"
                                                                        label="예"
                                                                        checked={selected_survey[key].answer === "예"}
                                                                        onChange={(e) => this._changeSurveyOption(e, data.id, key)} />
                                                                    <Form.Radio
                                                                        isInline
                                                                        value="아니오"
                                                                        label="아니오"
                                                                        checked={selected_survey[key].answer === "아니오"}
                                                                        onChange={(e) => this._changeSurveyOption(e, data.id, key)} />
                                                                    <Form.Radio
                                                                        isInline value="모름"
                                                                        label="모름"
                                                                        checked={selected_survey[key].answer === "모름"}
                                                                        onChange={(e) => this._changeSurveyOption(e, data.id, key)} />
                                                                </Form.Group>
                                                            )
                                                        }
                                                    </Grid.Col>
                                                    <Grid.Col lg={3} sm={12}>
                                                        <div className="preview"
                                                            style={{ height: 150, backgroundColor: '#222', overflow: 'hidden' }}></div>
                                                        <Form.Group>
                                                            <Button.List>
                                                                <Button block color="primary"
                                                                    onClick={this._handleAdd}
                                                                    disabled={this.state.question && this.state.questionAnswer.indexOf(-2) !== -1}
                                                                >이미지 자르기</Button>
                                                                <div>
                                                                    {
                                                                        this.state.showChange &&
                                                                        <div className="mt-3 ml-2">
                                                                            <p>수정하실 정답을 입력해주세요 <button
                                                                                className="btn btn-success ml-1"
                                                                                onClick={this._completeChangeAnswer}>완료</button></p>
                                                                            <input className="form-control" type="text" id="changeText"
                                                                                value={this.state.change_text_input}
                                                                                onChange={e => this.setState({ change_text_input: e.target.value })} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <Button block color="warning"
                                                                    onClick={() => this._handleSubmit()}
                                                                    disabled={!this.state.answers.length}
                                                                >완료
                                      </Button>
                                                            </Button.List>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Button.List>
                                                                {
                                                                    this.state.image_path &&
                                                                    this.state.image_label_list.map((data, key) =>
                                                                        <Button
                                                                            onClick={() => this._click(key)}> {data.id} / {data.answer}</Button>
                                                                    )
                                                                }
                                                            </Button.List>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            {
                                                                this.state.showModify &&
                                                                <Button.List>
                                                                    <Button color="success" onClick={this._changeAnswer2}>수정</Button>
                                                                    <Button color="danger" onClick={this._deleteAnswer2}>삭제</Button>
                                                                </Button.List>
                                                            }
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Col lg={12} sm={12}>
                                                        <div style={{
                                                            textAlign: 'center',
                                                            backgroundColor: '#ced4da',
                                                            color: '#495057',
                                                            height: 30,
                                                            padding: 5,
                                                            borderRadius: 5
                                                        }} className="mt-2">
                                                            자른 이미지 - {this.state.img !== undefined ?
                                                                this.state.img.split('/')[5] : null}
                                                        </div>
                                                        <ScrollArea
                                                            style={{ width: '100%', maxHeight: 600, whiteSpace: "nowrap" }}
                                                            className="area mt-5"
                                                            contentClassName="content d-inline-block"
                                                            horizontalScrollbarStyle={{ borderRadius: 5 }}
                                                            smoothScrolling={true}
                                                            minScrollSize={40}
                                                            swapWheelAxes={true}>
                                                            {itemElements}
                                                        </ScrollArea>
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </div>
                                            : null
                                    }
                                    <div className='w-50 row'>
                                        <div className='ml-5'>
                                            <Link to='/data-labeling/iPixel/inspection/'>
                                                <div className='btn btn-warning'>목록</div>
                                            </Link>
                                        </div>
                                        <div className='ml-auto'>
                                            <button className='btn btn-warning' onClick={this._approve}>검수 완료</button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </Card.Body>
                </Card>
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_return_images_for_detail: (label_id) => {
            return dispatch(projects.get_return_images_for_detail(label_id))
        },
        change_images_for_inspection_detail: (data) => {
            return dispatch(projects.change_images_for_inspection_detail(data))
        },
        change_survey_for_inspection_detail: (data) => {
            return dispatch(projects.change_survey_for_inspection_detail(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Return_ipixel);