import React from 'react';
import { projects } from "../../actions";
import { connect } from "react-redux";
import { Page, Grid, GalleryCard, Button } from "tabler-react";
import '../../App.css'
import './ipixel.css'

class ReturnList_ipixel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: true,
            data: []
        }
    }
    componentDidMount() {
        this._callAPI()
    }
    //프로젝트명(ipixel), type(반려, 작업불가를) 넘겨서 이미지 id랑 주소를 가져옵니다. 기본값은 반려
    _callAPI(type = "반려") {
        const project = "ipixel"
        this.props.get_not_valid_image(project, type).then(res =>
            this.setState({
                view: true,
                data: res.image_data
            })
        )
    }
    //select 변경시 실행 type이 바뀔시 재검색
    handleChange = (e) => {
        this.setState({
            view: false
        })
        this._callAPI(e.target.value)
    }
    comeback = (id) => {
        console.log(id)
    }
    render() {
        const { data } = this.state;
        const options = (
            <React.Fragment>
                <select onChange={e => this.handleChange(e)} className="custom-select">
                    <option value={"반려"}>반려</option>
                    <option value={"작업불가"}>작업불가</option>
                </select>
            </React.Fragment>
        )
        return (
            <Page.Content>
                <Page.Header
                    title="반려페이지"
                    options={options}
                />
                <Grid.Row>
                    {this.state.view ?
                        data.map((data, key) => (
                            <Grid.Col xl={2} sm={6} md={3} key={key}>
                                <GalleryCard>
                                    <GalleryCard.Image
                                        href={`list/${data.id}`}
                                        className={"inspectionCard"}
                                        src={data.path}
                                        alt={data.id}
                                    />
                                    <Button color="primary" onClick={e => this.comeback(data.id)}>작업 복귀</Button>
                                </GalleryCard>
                            </Grid.Col>
                        )) : null
                    }
                </Grid.Row>
                {/* <Grid.Row>
                    <Grid.Col className={"text-center"}>
                        <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={1}
                        totalItemsCount={this.state.total_pages}
                        pageRangeDisplayed={5}
                        onChange={e=>this.handlePageChange(e)}
                        />
                    </Grid.Col>
                </Grid.Row> */}
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        get_not_valid_image: (project, type) => {
            return dispatch(projects.get_not_valid_image(project, type))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnList_ipixel);