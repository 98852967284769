export const agree_policy = () => {
    return (dispatch, getState) => {
        dispatch({ type: "AGREE_POLICY" })
    }
}

export const disagree_policy = () => {
    return (dispatch, getState) => {
        dispatch({ type: "DISAGREE_POLICY" })
    }
}