import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import "tabler-react/dist/Tabler.css";
import {connect, Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {auth, policy} from "./actions";
import datarepublicApp from "./reducers";
import {ClimbingBoxLoader} from 'react-spinners';
import {css} from '@emotion/core';

import CompanyList from './pages/CompanyList';

import Home from "./pages/Home";
import Home2 from "./pages/Home2";


import RegisterPage from "./pages/RegisterPage"
import Login from "./pages/auth/Login"
import Logout from "./pages/auth/Logout"
import AboutUS from "./pages/auth/AboutUS"
import SiteWrapper from "./pages/SiteWrapper";
import WorkerHome from "./pages/worker/WorkerHome";
import ProjectList from "./pages/ProjectList";
import Policy from "./components/Policy"
import requestPayment from "./pages/requestPayment"
import ImageLabeling_ipixel from "./pages/ipixel/ImageLabeling_ipixel";
import MenuSelect_ipixel from "./pages/ipixel/MenuSelect_ipixel"
import ImageUpload_ipixel from "./pages/ipixel/ImageUpload_ipixel"
import Inspection_ipixel from "./pages/ipixel/Inspection_ipixel"
import InspectionDetail_ipixel from "./pages/ipixel/InspectionDetail_ipixel"

import Profile from "./pages/worker/Profile";
import Payment from "./pages/worker/Payment";

import LetterLabeling from "./pages/postcube/LetterLabeling";
import PostcubeInspectionList from "./pages/postcube/PostcubeInspectionList";
import LetterInspection from "./pages/postcube/LetterInspection";
import CategoryInspection from "./pages/postcube/CategoryInspection";
import LetterInspectionTest from "./pages/postcube/LetterInspectionTest";
import LetterImageUpload from "./pages/postcube/LetterImageUpload";

import InspectionList_ipixel from './pages/ipixel/InspectionList_ipixel';
import InspectionEdit_ipixel from './pages/ipixel/InspectionEdit_ipixel';
import Return_ipixel from './pages/ipixel/Return_ipixel';
import ReturnList_ipixel from './pages/ipixel/ReturnList_ipixel';

let store = createStore(datarepublicApp, applyMiddleware(thunk));

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class RootContainerComponent extends Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        console.log('load user');
        console.log(this.props.policy)
        await this.props.loadUser();
        await this.setState({loading: false})
    }


    render() {
        const {auth, policy} = this.props
        return (
            <BrowserRouter>
                <Switch>
                    {
                    this.state.loading&&
                        <React.Fragment>
                        <div className='sweet-loading' style={{marginTop: '20%'}}>
                            <ClimbingBoxLoader
                                css={override}
                                sizeUnit={"px"}
                                size={20}
                                color={'#90CAF9'}
                                loading={this.state.loading}
                            />
                        </div>
                        </React.Fragment>
                    }{
                    !this.state.loading&&auth.isAuthenticated&&
                        <Switch>
                            <SiteWrapper>
                                <Route exact path="/"
                                        component={auth.user.is_manager ? Home : WorkerHome}/>
                                <Route exact path="/projects/"
                                        component={auth.user.is_manager ? CompanyList : ProjectList}/>

                                <Route exact path="/company-list/post-cube/"
                                        component={auth.user.is_manager ? PostcubeInspectionList : null}/>

                                <Route exact path="/post-cube/inspection/image/"
                                        component={auth.user.is_manager ? CategoryInspection : null}/>

                                <Route exact path="/post-cube/inspection/letter/"
                                        component={auth.user.is_manager ? LetterInspection : null}/>
                                
                                <Route exact path="/post-cube/inspection/test/"
                                        component={auth.user.is_manager ? LetterImageUpload : null}/>
                                
                                <Route exact path="/payment/"
                                        component={auth.user.is_manager ? requestPayment : Payment}/>

                                <Route exact path="/company-list/ipixel/" component={MenuSelect_ipixel}/>
                                <Route exact path="/ipixel/labeling/"
                                component={policy.agree ? ImageLabeling_ipixel : Policy}/>
                                <Route exact path="/ipixel/upload/"
                                component={policy.agree ? ImageUpload_ipixel : Policy}/>
                                <Route exact path="/ipixel/inspection/"
                                component={policy.agree ? Inspection_ipixel : Policy}/>
                                <Route exact path="/ipixel/inspection/:inspection_id"
                                component={policy.agree ? InspectionEdit_ipixel : Policy}/>
                                <Route exact path="/ipixel/inspection/detail/list/"
                                component={policy.agree ? InspectionList_ipixel : Policy}/>
                                <Route exact path="/ipixel/inspection/detail/list/:inspection_id/"
                                component={policy.agree ? InspectionDetail_ipixel : Policy}/>
                                <Route exact path="/ipixel/return/list/"
                                component={ReturnList_ipixel}/>
                                <Route exact path="/ipixel/return/list/:inspection_id/"
                                component={policy.agree ? Return_ipixel : Policy}/>
                                <Route exact path="/logout/" component={Logout}/>
                                <Route exact path="/profile/" component={Profile}/>
                                <Route exact path="/letter-labeling/" component={LetterLabeling}/>
                            </SiteWrapper>
                        </Switch>
                    }
                    {
                        !this.state.loading&&!auth.isAuthenticated&&
                        <React.Fragment>
                            <Route exact path="/" component={Login}/>
                            <Route exact path="/register/" component={RegisterPage}/>
                            <Route exact path="/aboutus/" component={AboutUS}/>
                        </React.Fragment>
                    }
                </Switch>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        policy: state.policy
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => {
            return dispatch(auth.loadUser());
        }
    }
}

let RootContainer = connect(mapStateToProps, mapDispatchToProps)(RootContainerComponent);

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>


                <RootContainer/>

            </Provider>
        )
    }
}
