import React from 'react';
import { Page, BlogCard, Grid } from "tabler-react";
import "../../css/main.css"

class PostcubeInspectionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [
        {
          title: "한 글자 레이블링 작업",
          postUrl: "/letter-labeling/",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "wd1kr1",
          profileHref: "profile/wd1kr1/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2018/11/12/10/12/calligraphy-3810555_1280.jpg",
          imgAlt: "포스트큐브",
          iconName: "heart",
          iconHref: "#",
          postHref: "/letter-labeling/",
          description: "한 글자 레이블링 작업, 한 글자 검수 작업, 우편 검수 작업"
        },
        {
          title: "한 글자 검수 작업",
          postUrl: "/post-cube/inspection/letter/",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "wd1kr1",
          profileHref: "profile/wd1kr1/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2016/04/30/13/09/texture-1362873_1280.jpg",
          imgAlt: "포스트큐브",
          iconName: "heart",
          iconHref: "#",
          postHref: "/post-cube/inspection/letter/",
          description: "한 글자 레이블링 작업, 한 글자 검수 작업, 우편 검수 작업"
        },
        {
          title: "카테고리 검수 작업",
          postUrl: "/post-cube/inspection/image/",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "wd1kr1",
          profileHref: "profile/wd1kr1/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2013/08/28/16/07/shield-176795_1280.jpg",
          imgAlt: "포스트큐브",
          iconName: "heart",
          iconHref: "#",
          postHref: "/post-cube/inspection/image/",
          description: "한 글자 레이블링 작업, 한 글자 검수 작업, 우편 검수 작업"
        },
        {
          title: "우편 검수 작업",
          postUrl: "/post-cube/inspection/post/",
          description: " ",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "wd1kr1",
          profileHref: "profile/wd1kr1/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2017/08/30/10/46/mail-2696657_1280.jpg",
          imgAlt: "아이픽셀",
          iconName: "heart",
          iconHref: "#",
          postHref: "/post-cube/inspection/post/",
          description: "한 글자 레이블링 작업, 한 글자 검수 작업, 우편 검수 작업"
        },

      ]
    }
  }
  render() {
    return (
      <Page.Content title="작업 목록">
        <Grid.Row cards deck>
          {
            this.state.companyList.map((data) =>
              <Grid.Col sm={6} xl={3}>
                <BlogCard
                  className="join_list"
                  title={data.title}
                  postUrl={data.postUrl}
                  description={data.description}
                  avatarImgSrc={data.avatarImgSrc}
                  authorName={data.authorName}
                  profileHref={data.profileHref}
                  date={data.date}
                  imgSrc={data.imgSrc}
                  imgAlt={data.imgAlt}
                  postHref={data.postHref}
                />
              </Grid.Col>
            )
          }
        </Grid.Row>
      </Page.Content>
    )
  }
}

export default PostcubeInspectionList;