import * as React from "react";

import {
    Page,
    Icon,
    Grid,
    Card,
    Table,
    colors,
    Button,
    StatsCard,
    Header
} from "tabler-react";
import {useTable} from 'react-table'

import C3Chart from "react-c3js";

import 'c3/c3.css';
import {auth} from "../../actions";
import {connect} from "react-redux";


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            point_table: [
                {
                    id: 1,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 업로드',
                    work_count: 15,
                    cost_per_work: 50,
                    points: 15*50
                },
                {
                    id: 2,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 라벨링',
                    work_count: 1934,
                    cost_per_work: 30,
                    points: 1934 * 30
                },
                {
                    id: 3,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 검수',
                    work_count: 78,
                    cost_per_work: 10,
                    points: 78 * 10
                },
                {
                    id: 4,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 라벨링 검수',
                    work_count: 778,
                    cost_per_work: 20,
                    points: 778 * 20
                },
                 {
                    id: 5,
                    project_name: "우편 글자 판별",
                    work_name: '한글자 작업',
                    work_count: 9837,
                    cost_per_work: 10,
                    points: 9837 * 10
                },

            ],
            columns: [
                {
                    Header: '완료된 작업',
                    columns: [
                        {
                            Header: '번호',
                            accessor: 'id',
                        },
                        {
                            Header: '프로젝트명',
                            accessor: 'project_name',
                        },
                        {
                            Header: '작업',
                            accessor: 'work_name',
                        },
                        {
                            Header: '작업건수',
                            accessor: 'work_count',
                        },
                        {
                            Header: '단가기준',
                            accessor: 'cost_per_work',
                        },
                        {
                            Header: '포인트',
                            accessor: 'points',
                        },
                    ],
                }
            ],
            home: '',
            workerRankingArray: [
                {
                    total_works: 49,
                    points: 4900,
                    date: '2019-08-02'
                },
                {
                    total_works: 202,
                    points: 20200,
                    date: '2019-08-04'
                },
                {
                    total_works: 391,
                    points: 39100,
                    date: '2019-08-10'
                },
                {
                    total_works: 349,
                    points: 34900,
                    date: '2019-08-11'
                },
                {
                    total_works: 124,
                    points: 12400,
                    date: '2019-08-12'
                },
            ],

        }
    }


    componentDidMount() {
        this.props.home().then(res => {
            console.log(res);
            this.setState({home: res});
        })
    }


    render() {
      const {home} = this.state
        function Table({columns, data}) {
            // Use the state and functions returned from useTable to build your UI
            const {getTableProps, headerGroups, rows, prepareRow} = useTable({
                columns,
                data,
            });

            // Render the UI for your table
            return (
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {rows.map(
                        (row, i) =>
                            prepareRow(row) || (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                    )}
                    </tbody>
                </table>
            )
        }


        return (

            <Page.Content title="대시 보드">
                <Grid.Row cards={true}>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard layout={1} movement={0} total={this.state.home.today} label="오늘의 작업수"/>
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard
                            layout={1}
                            movement={0}
                            total={home.month}
                            label="이달의 작업수"
                        />
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard layout={1} movement={0} total={home.total} label="총 작업수"/>
                    </Grid.Col>
                    <Grid.Col width={12} sm={8} lg={4}>
                        <StatsCard
                            layout={1} movement={0}
                            total={home === '' ? null : home.point_table[home.point_table.length-1].points.toLocaleString() + ' 원'}
                            label="총 포인트"/>
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard
                          layout={1}
                          movement={0}
                          total={home === '' ? null : home.point_table[home.point_table.length-1].cost_per_work.toLocaleString() + ' 원'}
                          label="총 지급된 현금"
                        />
                    </Grid.Col>
                    <Grid.Col lg={6}>
                        <Table columns={this.state.columns} data={home === '' ? this.state.point_table : home.point_table}/>
                    </Grid.Col>
                    <Grid.Col md={6}>
                        <Grid.Row>
                            <Grid.Col sm={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>나의 작업 검수율</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {
                                            this.state.home === '' ? null :
                                                <C3Chart
                                                    style={{height: "12rem"}}
                                                    data={{
                                                        columns: this.state.home.approved_data_column,
                                                        type: "donut", // default type of chart
                                                        colors: {
                                                            data1: colors["green"],
                                                            data2: colors["green-light"],
                                                        },
                                                        names: {
                                                            // name of each serie
                                                            data1: "완료",
                                                            data2: "미완료",
                                                        },
                                                    }}
                                                    legend={{
                                                        show: false, //hide legend
                                                    }}
                                                    padding={{
                                                        bottom: 0,
                                                        top: 0,
                                                    }}
                                                />
                                        }
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                            {/*<Grid.Col sm={6}>*/}
                            {/*    <Card>*/}
                            {/*        <Card.Header>*/}
                            {/*            <Card.Title>포인트</Card.Title>*/}
                            {/*        </Card.Header>*/}
                            {/*        <Card.Body>*/}
                            {/*            <Button.List>*/}
                            {/*                <Button RootComponent="a" href="/payment/" block color="primary">*/}
                            {/*                    환급받기*/}
                            {/*                </Button>*/}
                            {/*                <Button block color="secondary">*/}
                            {/*                    계좌 수정*/}
                            {/*                </Button>*/}
                            {/*                <Header.H3 className={'mt-3'}>국민은행{'\n'} 25180204154264</Header.H3>*/}
                            {/*            </Button.List>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Grid.Col>*/}
                            {/*<Grid.Col sm={6}>*/}
                            {/*    <Card*/}
                            {/*        title="최근 진행한 프로젝트"*/}
                            {/*        body={*/}
                            {/*            <Button block color="info">*/}
                            {/*                프로젝트1*/}
                            {/*            </Button>*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*</Grid.Col>*/}
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>

        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        home: () => {
            return dispatch(auth.home());
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);

