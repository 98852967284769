import React from 'react';
import { projects } from "../../actions";
import { connect } from "react-redux";
import { SelectBox } from "../../components/SelectBox"
import { Page, Grid, Form, Button, Dimmer } from "tabler-react";
import InspectionEdit_ipixel from "./InspectionEdit_ipixel"

class Inspection_ipixel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: false,            //사진을 보여줄지 말지 결정
            page: 1,                //페이지
            filter: '검수 미완료',
            objectType: [],
            category: '',
            images: [],
            total_images: '',
            labeled_images: '',
            total_approved: '',
            data: [],                //넘겨줄 데이터 id의 배열
            edit: false,             //true면 상세페이지를 보여줌
            id: 1                    //상세페이지 시작id
        }
    }

    onChange(e) {
        this.setState({
            filter: e.target.value
        })
    }
    get_object_type = (val) => {
        if (this.state.page !== 1)
            alert("카테고리 변경")

        this.setState({
            category: val,
            page: 1
        })
    };
    // onChange2(e) {
    //     this.setState({
    //         category: e.target.value
    //     })
    // }
    componentDidMount() {
        this.props.get_object_type().then(response =>
            this.setState({
                objectType: response
            })
        );
    }

    //검색 아이콘 눌렀을시 함수
    search = () => {
        let category = this.state.category;
        let filter = this.state.filter;
        let page = this.state.page;
        const is_valid_image = "f";
        if (category === '') {
            category = this.state.objectType[0].title
        }
        //filter, category page를 파라미터로 넘겨주고 검색

        this.props.get_images_for_inspection(filter, category, page, is_valid_image).then(res => {
            //검색한 데이터에 id값을 가져와서 data state에 저장해줌
            console.log(res);
            const data = res.image_data.map(data =>
                data.id
            );
            this.setState({
                edit: false,
                view: true,
                data: data,
                images: res.image_data,
                total_images: res.total_images,
                labeled_images: res.labeled_images,
                total_approved: res.total_approved
            })

        }
        )
    };
    //사진을 클릭하면 data state에 id를 빼고,한번더 클릭하면 더해줍니다.
    imageCheck = (e) => {
        let data = this.state.data
        //체크가 돼있을경우 데이터 빼줌
        if (e.target.checked) {
            data.splice(data.indexOf(e.target.value), 1);
            this.setState({
                data: data
            })
            //체크가 안돼 있을경우 데이터 추가해줌
        } else {
            data.push(e.target.value)
            this.setState({
                data: data
            })
        }
    }
    //이미지를 더블클릭하면 반려/불가 페이지로 이동합니다.
    onDoubleClick = (id) => {
        this.setState({
            edit: true,
            id
        })
    }
    _submit = async () => {
        //view를 false로 바꿔줌(갱신된 데이터를 다시 map 하기 위해)
        await this.setState({
            images: [],
            view: false
        });
        await this.setState(prevState => {
            return {
                page: prevState.page + 1,
                view: false
            }
        })
        //검수된 데이터의 id를 배열로 전송함
        alert("success")
        console.log(this.state.data)
        await this.props.submit_first_inspection_data(this.state.data);
        await this.search()
    };

    render() {
        const options = (
            <React.Fragment>
                <Form.Group className="m-1" label="정렬 기준">
                    <Form.Select value={this.state.filter} onChange={this.onChange.bind(this)}>
                        <option value="작업 날짜 (오래된 순)">작업 날짜 (오래된 순)</option>
                        <option value="검수 미완료">검수 미완료</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="m-1" label="카테고리">
                    <Grid.Row gutters="xs">
                        <Grid.Col>
                            {
                                this.state.objectType ?
                                    <SelectBox sendData={this.get_object_type} data={this.state.objectType} />
                                    : null
                            }
                        </Grid.Col>
                        <Grid.Col auto>
                            <Button
                                onClick={this.search}
                                color="secondary"
                                icon="search"
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Form.Group>
            </React.Fragment>
        )
        return (
            <Page.Content>
                <Page.Header
                    title="검수페이지"
                    subTitle={`${this.state.data.length}/${this.state.images.length}`}
                    options={options}
                />
                {this.state.view &&
                    <React.Fragment>
                        <Grid.Row className="row-cards">
                            <Dimmer active={false} loader>
                                <Form.Group>
                                    <Form.ImageCheck>
                                        {this.state.edit &&
                                            <React.Fragment>
                                                <Button onClick={e => this.setState({ edit: false })}>X</Button>
                                                <InspectionEdit_ipixel
                                                    images={this.state.images}
                                                    key={this.state.key}
                                                    id={this.state.id}
                                                    objectType={this.state.objectType}
                                                />
                                            </React.Fragment>
                                        }
                                        <React.Fragment>
                                            <Grid.Row>
                                                {this.state.images.map((image, key) => (
                                                    <div className={"col-6 col-md-2"}
                                                        onDoubleClick={e => this.onDoubleClick(key)}>
                                                        <Form.ImageCheckItem
                                                            col={{ xs: 12, md: 12, sm: 12, lg: 12 }}
                                                            onClick={e => this.imageCheck(e)}
                                                            imageURL={'https://datarepublic-upload-files.s3.ap-northeast-2.amazonaws.com/' + image.path}
                                                            value={image.id}
                                                        />
                                                    </div>
                                                ))}
                                            </Grid.Row>
                                        </React.Fragment>

                                    </Form.ImageCheck>
                                </Form.Group>
                            </Dimmer>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={12}>
                                <Button.List align="center">
                                    <Button color="success" onClick={this._submit}>이 페이지 검수 완료</Button>
                                </Button.List>
                            </Grid.Col>
                        </Grid.Row>
                    </React.Fragment>
                }
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        form: state.form

    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_object_type: () => {
            return dispatch(projects.get_object_type())
        },
        get_images_for_inspection: (filter, category, page, is_valid_image) => {
            return dispatch(projects.get_images_for_inspection(filter, category, page, is_valid_image))
        },
        submit_first_inspection_data: (id) => {
            return dispatch(projects.submit_first_inspection_data(id))
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Inspection_ipixel);