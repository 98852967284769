import * as React from "react";
import { StandaloneFormPage, FormCard, Button, Form } from "tabler-react"
import { connect } from "react-redux";
import { auth } from "../../actions";
class Login extends React.Component {
  state = {
    username: "",
    password: "",
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };
  render() {
    return (
      <StandaloneFormPage imageURL="https://cdn.iconscout.com/icon/premium/png-256-thumb/ai-75-806497.png">
        <FormCard
          onSubmit={this.onSubmit}
          title="Login"
          buttonText="Login"
          method="get"
        >
          <Form.FieldSet>
            <Form.Group>
              <Form.Input
                name="username"
                placeholder="ID/Email"
                onChange={e => this.setState({ username: e.target.value })} />
            </Form.Group>
            <Form.Group>
              <Form.Input
                name="password"
                type="password"
                placeholder="Password"
                onChange={e => this.setState({ password: e.target.value })} />
            </Form.Group>
          </Form.FieldSet>
        </FormCard>
        <Button RootComponent="a" href="/register/">Create Account</Button>
        　　　  　　　　　　
        <Button RootComponent="a" href="/aboutus/">About US</Button>
      </StandaloneFormPage>
    )
  }
}
const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return { field, message: state.auth.errors[field] };
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);