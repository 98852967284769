import React from "react"
import { Card, Button, Grid, Page } from "tabler-react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import AWS from 'aws-sdk';
import { SelectBox } from "../../components/SelectBox"
import { projects } from "../../actions";
import { connect } from "react-redux";
import {GUIDELINE_URL} from "../../settings/urls"

let s3 = new AWS.S3({
    accessKeyId: 'AKIAXZDWI3C7NXRRG5AD',
    secretAccessKey: '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',
    region: 'ap-northeast-2'
});

class LetterImageUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            image_all: 0,
            image_object: 0,
            labeled: 0
        }
    }
    componentDidMount() {
        this._callAPI()
    }

    _callAPI = () => {
        this.props.get_object_type().then(response =>
          console.log(response)
          // this.setState({
            //     objectType: response
            // })
        );

        this.props.get_image_type().then(response =>
            this.setState({
                imageType: response
            })
        )
    };

    get_object_type = (val) => {
        this.props.get_image_upload_count(val).then(res =>
            //console.log(res)
            this.setState({
                image_all: res.image_all,
                image_object: res.image_object,
                labeled: res.labeled
            })
        )
        this.setState({
            objectOption: val
        })
    };
    get_image_type = (val) => {
        this.setState({
            imageOption: val
        })
    };
    _save = async() => {
        let files = this.pond.getFiles();
        let serverIdArray = files.map(file => file.serverId);
        let data = {
            serverIdArray: serverIdArray,
            objectOption: this.state.objectOption,
            project: 'postcube'
        };
        console.log(data)
    };
    handleOpenModal() {
        window.location = GUIDELINE_URL
    }
    render() {
        const objectType=[
          {id: 1, title: 1, hide: false},
          {id: 2, title: 2, hide: false},
          {id: 3, title: 3, hide: false},
          {id: 4, title: 4, hide: false},
          {id: 5, title: 5, hide: false},
          {id: 6, title: 6, hide: false},
          {id: 7, title: 7, hide: false},
        ]
        const options = (
            <React.Fragment>
                전체 이미지 갯수: {this.state.image_all}
                이미지 갯수 : {this.state.image_object}
                레이블된 이미지 갯수 : {this.state.labeled}
            </React.Fragment>
        )
        return (
            <Page.Content title="업로드 페이지" options={options}>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Body>
                                <Grid.Row>
                                    <Grid.Col md={5} sm={12}>
                                        {
                                            objectType ?
                                                <SelectBox sendData={this.get_object_type} data={objectType} />
                                                : null
                                        }
                                    </Grid.Col>
                                    <Grid.Col md={2}>
                                        <Button onClick={e => this.handleOpenModal()} size="sm" color="primary">가이드 보기</Button>
                                    </Grid.Col>
                                    <Grid.Col lg={12} className="mt-3">
                                        <FilePond ref={ref => this.pond = ref}
                                            files={this.state.files}
                                            allowMultiple={true}
                                            server={
                                                {
                                                    url: '/uploader',
                                                    process: function (fieldName, file, metadata, load, error, progress, abort) {
                                                        s3.upload({
                                                            Bucket: 'datarepublic-upload-files/postcube',
                                                            Key: Date.now() + '_' + file.name,
                                                            Body: file,
                                                            ContentType: file.type,
                                                            ACL: 'public-read'
                                                        }, function (err, data) {
                                                            let image_url = data.Location;
                                                            if (err) {
                                                                error('Something went wrong');
                                                                return;
                                                            }
                                                            // pass file unique id back to filepond
                                                            load(data.Key);
                                                        });
                                                    },
                                                }
                                            }
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                <Button onClick={this._save} color="primary" disabled={this.state.image_object >= 500}>
                                    완료
                            </Button>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        policy: state.policy
    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_object_type: () => {
            return dispatch(projects.get_object_type())
        },
        get_image_type: () => {
            return dispatch(projects.get_image_type())
        },
        create_image_multiple_choice_project: (data) => {
            return dispatch(projects.create_image_multiple_choice_project(data))
        },
        get_image_upload_count: (objectType) => {
            return dispatch(projects.get_image_upload_count(objectType))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(LetterImageUpload)