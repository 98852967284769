import React from "react"
import Modal from "react-modal"
import { Grid, Form, Button } from "tabler-react"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
Modal.setAppElement('#root')
const ReturnModal = props => {
  const _handleOptionChange = (changeEvent) => {
    const reasonSelect = changeEvent.target.value
    props.sendData("reasonSelect", reasonSelect)
  }
  const _handleOptionChange2 = (changeEvent) => {
    const reasonText = changeEvent.target.value
    props.sendData("reasonText", reasonText)
  }
  const _handleInspect = () => {
    props.sendData("submit")
  }
  const closeModal = () => {
    props.sendData("modal", false)
  }
  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Grid.Row>
        <Button onClick={closeModal}>x</Button>
      </Grid.Row>
      <Form.Group label="반려사유">
        <Form.Radio
          value={1}
          onChange={e=>_handleOptionChange(e)}
          label="영역을 잘못 지정함"
          name="return"
        />
        <Form.Radio
          value={2}
          onChange={e=>_handleOptionChange(e)}
          label="글자를 잘못 입력함"
          name="return"
        />
        <Form.Radio
          value={3}
          onChange={e=>_handleOptionChange(e)}
          label="기타"
          name="return"
        />
        {
          props.reasonSelect===3&&
          <Form.Input
          onChange={e=>_handleOptionChange2(e)}
          />
        }
      </Form.Group>
      <Button onClick={() => _handleInspect('not_workable2')}
        color="danger">반려
      </Button>
    </Modal>
  )
}
export default ReturnModal