import React from 'react';
import {Card, Page, Grid, Button, Table, Text, Avatar, Progress, Icon, Dropdown} from "tabler-react";
import {useTable} from "react-table";
import {projects} from "../actions";
import {connect} from "react-redux";

class requestPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            r:[],
            response: [],
            loaded: false,
            point_table: [
                {
                    id: 1,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 업로드',
                    work_count: 15,
                    cost_per_work: 50,
                    points: 15 * 50
                },
                {
                    id: 2,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 라벨링',
                    work_count: 1934,
                    cost_per_work: 30,
                    points: 1934 * 30
                },
                {
                    id: 3,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 검수',
                    work_count: 78,
                    cost_per_work: 10,
                    points: 78 * 10
                },
                {
                    id: 4,
                    project_name: "사진 속 다양한 물체 식별",
                    work_name: '이미지 라벨링 검수',
                    work_count: 778,
                    cost_per_work: 20,
                    points: 778 * 20
                },
                {
                    id: 5,
                    project_name: "우편 글자 판별",
                    work_name: '한글자 작업',
                    work_count: 9837,
                    cost_per_work: 10,
                    points: 9837 * 10
                },

            ],
            columns: [
                {
                    Header: '완료된 작업',
                    columns: [
                        {
                            Header: '번호',
                            accessor: 'id',
                        },
                        {
                            Header: '프로젝트명',
                            accessor: 'project_name',
                        },
                        {
                            Header: '작업',
                            accessor: 'work_name',
                        },
                        {
                            Header: '작업건수',
                            accessor: 'work_count',
                        },
                        {
                            Header: '단가기준',
                            accessor: 'cost_per_work',
                        },
                        {
                            Header: '포인트',
                            accessor: 'points',
                        },
                    ],
                }
            ],
            data: []
        }
    }

    async componentDidMount() {
      const {response} = this.state
      await this.props.get_payment().then(res=>{
              res.map(data=>{
                setTimeout(() =>{
                  this.props.post_payment(data.user).then(res=>{
                    response.push(res[0])
                    console.log(response)
                    this.setState({ 
                      response : response,
                      loaded : true
                    })
                  })
                }, 3000)
              })
            }
        )
    }

    //환급 요청을 승인하면 해당 id를 넘겨줍니다.
    allow_payment(id) {
        console.log(id)
    }

    //환급 요청 시간을 현재 날짜와 비교하여 얼마전에 신청한 요청인지 반환해줌
    timeSince(date) {
        console.log(date)
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);
        let interval = Math.floor(seconds / 31536000);
        console.log(seconds)
        if (interval > 1) {
            return interval + "년 전";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + "달 전";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + "일 전";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + "시간 전";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + "분 전";
        }
        return Math.floor(seconds) + "초 전";
    }

    render() {
        function Table({columns, data}) {
            // Use the state and functions returned from useTable to build your UI
            const {getTableProps, headerGroups, rows, prepareRow} = useTable({
                columns,
                data,
            });

            // Render the UI for your table
            return (
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {rows.map(
                        (row, i) =>
                            prepareRow(row) || (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                    )}
                    </tbody>
                </table>
            )
        }

        return (
            <Page.Content title="적립금 지급">
                {
                    this.state.loaded ?
                        this.state.response.map((data)=>
                            <div style={{marginTop: 50}}>
                                <div>{data.user} / {data.profile.name} / {data.point_table[data.point_table.length-1].points}P  {data.profile.bank_name} {data.profile.bank_account}</div>
                                <Table columns={this.state.columns} data={data.point_table}/>
                            </div>
                            )
                        :
                        null
                }
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,

    }
};

const mapDispatchToProps = dispatch => {
    return {
      get_payment: () => {
        return dispatch(projects.get_payment())
    },
    post_payment: (username) => {
      return dispatch(projects.post_payment(username))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(requestPayment);

