import React from "react"
import { Page, Grid, Card, Button, Header } from "tabler-react";
import CustomCropper from "../../components/CustomCropper"
import { SelectBox } from "../../components/SelectBox"
import {GUIDELINE_URL} from "../../settings/urls"
import { projects } from "../../actions";
import { connect } from "react-redux";
import ReactModal from 'react-modal';

class ImageLabeling_ipixel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: [],
            answer: [],
            answers: [],
            questionAnswer: [],
            objectType: [],
            imageType: [],
            objectOption: 0,
            imageOption: 0,
            img_id: "",
            labeled_images: 0,
            total_images: 0,
            cropped_images :0,
            showModal: false
        }
    }

    componentDidMount() {
        this._callAPI()
    }

    _callAPI = () => {
        this.props.get_object_type().then(response =>
            //console.log(response)
            this.setState({
                objectType: response
            })
        );

    };
    get_object_type = (val) => {
        this.setState({
            objectOption: val
        })
    };

    _search = () => {
        // console.log(this.state.img);
        this.props.get_image_multiple_choice_project(this.state.objectOption).then(res => {
            console.log(res)
            this.setState({
                labeled_images: res.labeled_images,
                total_images: res.total_images,
                cropped_images: res.cropped_images,
                question: res.choices_list,
                img: res.images_data.path,
                img_id: res.images_data.id
            })
        })
    };

    _getdata = async (getData) => {
        if(getData.type==="wrong_image"){
            console.log(getData);
            getData = {
                ...getData,
                img_id: this.state.img_id
            }
        }else if(getData.type==="submit"){
            getData.map((item) => {
            item.img = "";
            item.img_id = this.state.img_id;
            item.question_list = this.state.question;
            });
          }
        this.props.submit_image_multiple_choice_project(getData).then(res => {
            console.log(getData);
            console.log(res);
        });
        await this.props.get_image_multiple_choice_project(this.state.objectOption).then(res => {
            console.log(res);
            this.setState({
                question: res.choices_list,
                img: res.images_data.path,
                img_id: res.images_data.id
            });
        });
        await this._search()
    }
    handleOpenModal() {
        // this.setState({ showModal: true });
        window.location = GUIDELINE_URL
    }
    handleCloseModal() {
        this.setState({ showModal: false });
    }
    render() {
        return (

            <Page.Content
                title={`레이블링 페이지 ${this.state.labeled_images}/${this.state.total_images}`}
                subTitle={`cropped images : ${this.state.cropped_images}`}
            >
                <Card>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col md={4} sm={12}>
                                {
                                    this.state.objectType ?
                                        <SelectBox sendData={this.get_object_type} data={this.state.objectType} />
                                        : null
                                }
                            </Grid.Col>
                            <Grid.Col auto>
                                <Button
                                    onClick={e => this._search()}
                                    color="secondary"
                                    icon="search"
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <Button.List align="right">
                                    <Button onClick={e => this.handleOpenModal()} size="sm" color="primary">가이드 보기</Button>
                                </Button.List>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                </Card>
                <Grid.Row>
                    <Grid.Col lg={12} sm={12}>
                        <Card>
                            {this.state.img ?
                                <Card.Body>
                                    <CustomCropper
                                        objectOption={this.state.objectOption}
                                        objectType={this.state.objectType}
                                        question={this.state.question}
                                        _getdata={this._getdata}
                                        // _search={this._search}
                                        img={this.state.img} />
                                </Card.Body>
                                : <Header.H1>이미지 검색을 해주세요. 이미 끝난 작업일 수 있습니다.</Header.H1>
                            }
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_object_type: () => {
            return dispatch(projects.get_object_type())
        },
        get_image_multiple_choice_project: (object_type) => {
            return dispatch(projects.get_image_multiple_choice_project(object_type))
        },
        submit_image_multiple_choice_project: (data) => {
            return dispatch(projects.submit_image_multiple_choice_project(data))
        },

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageLabeling_ipixel)