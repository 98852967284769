import React from 'react';


class CropCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        return (
            <div className="card ml-auto mr-auto" style={{width: '13rem',height: '15rem', marginBottom: 20}}>
                <img className="card-img-top"
                     src={this.props.src}
                     alt={this.props.alt}/>
                <div className="card-body">
                    <p className="card-text">
                        {this.props.labeling}
                    </p>
                    <p className="card-text">
                        {this.props.answer}
                    </p>
                </div>
            </div>
        )
    }
}

export default CropCard;
