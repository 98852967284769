import PUBLIC_URL from '../settings'

export const loadUser = () => {
    console.log('user loading');
    return (dispatch, getState) => {
        dispatch({type: "USER_LOADING"});

        const token = getState().auth.token;
        console.log(token)

        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/auth/user/", {headers,})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        // console.log(res.status)
                        // console.log(data);

                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    // console.log('200')
                    dispatch({type: 'USER_LOADED', user: res.data});
                    return res.data;
                } else if (res.status >= 400 && res.status < 500) {
                    dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                    return res.data;
                }
            })
    }
};

export const login = (username, password) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let body = JSON.stringify({username, password});

        return fetch(PUBLIC_URL + "/api/auth/login/", {headers, body, method: "POST"})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data, user: res.data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    dispatch({type: 'CLOSE_MODAL'});
                    dispatch({type: 'LOGIN_SUCCESSFUL', data: res.data});
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                    throw res.data;
                } else {
                    dispatch({type: "LOGIN_FAILED", data: res.data});
                    throw res.data;
                }
            })
    }
}

export const register = (info) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let body = JSON.stringify({info});

        return fetch(PUBLIC_URL + "/api/auth/register/", {headers, body, method: "POST"})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    dispatch({type: 'REGISTRATION_SUCCESSFUL', data: res.data});
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                    throw res.data;
                } else {
                    dispatch({type: "REGISTRATION_FAILED", data: res.data});
                    throw res.data;
                }
            })
    }
};

export const logout = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};

        return fetch(PUBLIC_URL + "/api/auth/logout/", {headers, body: "", method: "POST"})
            .then(res => {
                console.log(res.data);
                if (res.status === 204) {
                    return {status: res.status, data: {}};
                } else if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    dispatch({type: 'CLOSE_MODAL'});
                    dispatch({type: 'LOGOUT_SUCCESSFUL'});
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                    throw res.data;
                }
            })
    }
};

export const check_username_valid = (username) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};

        let body = JSON.stringify({username});


        return fetch(PUBLIC_URL + "/api/auth/check/username/", {headers, body, method: "POST"})
            .then(res => {
                console.log(res.data);
                if (res.status === 204) {
                    return {status: res.status, data: {}};
                } else if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    return res.data;
                }
            })
    }
};

export const home = () => {
    return (dispatch, getState) => {

        const token = getState().auth.token;

        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
            console.log(headers)
        }
        return fetch(PUBLIC_URL + "/api/projects/home/?user_id=" + getState().auth.user.user_id, {headers,})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                } else if (res.status >= 400 && res.status < 500) {
                    throw res.data;
                }
            })
    }
};

export const manager_home = () => {
    return (dispatch, getState) => {

        const token = getState().auth.token;

        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
            console.log(headers)
        }
        return fetch(PUBLIC_URL + "/api/projects/manager_home/?user_id=1", {headers,})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                } else if (res.status >= 400 && res.status < 500) {
                    throw res.data;
                }
            })
    }
};


export const mypage = () => {
    return (dispatch, getState) => {

        const token = getState().auth.token;

        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
            console.log(headers)
        }
        return fetch(PUBLIC_URL + "/api/auth/mypage/", {headers,})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                } else if (res.status >= 400 && res.status < 500) {
                    throw res.data;
                }
            })
    }
};
export const editProfile = (info) => {
    return (dispatch, getState) => {

        let headers = {
            "Content-Type": "application/json",
        };

        const token = getState().auth.token;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
            console.log(headers)
        }
        let body = JSON.stringify({info});
        console.log(info);
        return fetch(PUBLIC_URL + "/api/auth/change/profile/", {headers, body, method: "POST"})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    alert(res.data);
                } else {
                    alert(res.data);
                }
            })
    }
};

export const user_profile = () => {
    return (dispatch, getState) => {

        const token = getState().auth.token;

        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
            console.log(headers)
        }
        return fetch(PUBLIC_URL + "/api/auth/user/profile/", {headers,})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                } else if (res.status >= 400 && res.status < 500) {
                    throw res.data;
                }
            })
    }
};