import React from 'react';
import { BlogCard, Grid } from "tabler-react";
import SiteWrapper from "./SiteWrapper";


class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [
        {
          title: "우편 글자 판별 프로젝트",
          postUrl: "/letter-labeling/",
          description: "우편물 이미지에 있는 글자를 판별하는 인공지능 알고리즘을 제작하기 위한 프로젝트입니다. 특히 한글 손글씨를 판별하는 알고리즘은 고난이도의 기술이기 때문에 정확한 데이터 생성이 필요합니다.",
          avatarImgSrc: "https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891",
          authorName: "wd1kr1",
          profileHref: "profile/wd1kr1/",
          date: "마감일 2019-09-30",
          imgSrc: "https://cdn.pixabay.com/photo/2017/08/27/00/31/mailbox-2684842_1280.jpg",
          imgAlt: "포스트큐브",
          iconName: "heart",
          iconHref: "#",
          postHref: "/letter-labeling/"

        },
        {
          title: "사진 속 다양한 물체 식별 프로젝트",
          postUrl: "/company-list/ipixel/",
          description: "증강현실 게임에서 물체를 식별하는 인공지능 알고리즘을 제작하기 위한 프로젝트입니다. 다양한 사물들을 대상으로 게임을 제작하고 있기 때문에 다양한 카테고리가 필요하며, 다양한 환경에서의 이미지가 필요합니다.",
          avatarImgSrc: "https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891",
          authorName: "wd1kr1",
          profileHref: "profile/wd1kr1/",
          date: "마감일 2019-11-30",
          imgSrc: "https://cdn.pixabay.com/photo/2019/09/27/04/59/augmented-reality-4507522_1280.jpg",
          imgAlt: "아이픽셀",
          iconName: "heart",
          iconHref: "#",
          postHref: "/company-list/ipixel/"

        },

      ]
    }
  }

  render() {
    return (
      <div style={{ maxWidth: 800, margin: 'auto' }} className='mt-5'>
        <Grid.Row>
          {
            this.state.companyList.map((data, index) =>
              <Grid.Col md={12} key={index}>
                <BlogCard
                  aside
                  title={data.title}
                  postUrl={data.postUrl}
                  description={data.description}
                  avatarImgSrc={data.avatarImgSrc}
                  authorName={data.authorName}
                  profileHref={data.profileHref}
                  date={data.date}
                  imgSrc={data.imgSrc}
                  imgAlt={data.imgAlt}
                  postHref={data.postHref}
                />
              </Grid.Col>
            )
          }
        </Grid.Row>
      </div>
    )
  }
}

export default ProjectList;

