import React, {Component} from "react";
import {connect} from "react-redux";

import {auth} from "../../actions";
import {Card} from "tabler-react";

class Logout extends Component {

    render() {

        return (
            <div>
                <div className={'ml-auto mr-auto mt-5'} style={{maxWidth: 800}}>
                    <Card
                        statusColor="red"
                        title="로그아웃"
                        body={
                            <div>
                                <p>로그아웃 하시겠습니까?</p>
                                <button className={'btn btn-danger'} onClick={() => this.props.logout()}>로그아웃</button>
                            </div>
                        }
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            return dispatch(auth.logout());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
