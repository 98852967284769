import React from "react";
import { Page, Grid, Form, Card, Button } from "tabler-react"
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { projects } from "../../actions";
import { connect } from "react-redux";

class InspectionEdit_ipixel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            changeCategory: ''
        }
    }

    componentDidMount() {
        if (this.props.id > 0) {
            this.setState({
                id: this.props.id
            })
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (prevState !== nextProps) {
    //         return {...prevState.state, id: nextProps.id};
    //     }
    //     return null;
    // }

    nextPage() {
        if (this.state.id + 1 < this.props.images.length) {
            this.setState(prevState => ({
                id: prevState.id + 1
            }))
        } else {
            alert("마지막 이미지입니다.")
        }
    }

    prevPage() {
        if (this.state.id > 0) {
            this.setState(prevState =>
                ({
                    id: prevState.id - 1
                }))
        } else {
            alert("처음 이미지입니다.")
        }
    }

    _changeCategory(e) {
        let change_category = e.target.value;
        let data = {
            type: '카테고리 변경',
            change_category: change_category,
            image_id: this.props.images[this.state.id].id
        };
        // console.log(this.props.images[this.state.id].id)
        this.props.change_images_for_inspection_detail(data)

    }

    isImageValid(is_valid) {
        let data = {
            type: '이미지 검수',
            image_id: this.props.images[this.state.id].id,
            is_valid_image: is_valid
        };
        this.props.change_images_for_inspection_detail(data)
        this.nextPage()
    }

    render() {
        const { images, objectType } = this.props
        const { id } = this.state
        const image = images[id]
        return (
            <Page.Content className={"w-100"} key={this.props.id}>
                <Grid.Row>
                    <Grid.Col md={12} xl={12}>
                        <Card
                            title={`${id + 1}/${images.length}`}
                            isCollapsible
                        >
                            <Card.Body>
                                <Grid.Row>
                                    <Grid.Col md={6} sm={12}>
                                        <img
                                            className={"w-100"}
                                            src={`https://datarepublic-upload-files.s3.ap-northeast-2.amazonaws.com/${image.path}`}
                                            alt={image.path}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} sm={12}>
                                        <Form.Group label="카테고리 변경">
                                            <Grid.Row>
                                                <Grid.Col>
                                                    {/* <Button onClick={e=>console.log(this.props)}>df</Button> */}
                                                    <Form.Select onChange={e => this._changeCategory(e)}>
                                                        {
                                                            objectType.map((data, index) =>
                                                                <option value={data.title}
                                                                    selected={index + 1 === image.object_type}>{data.title}</option>
                                                            )
                                                        }
                                                    </Form.Select>
                                                </Grid.Col>
                                                <Grid.Col auto>
                                                    <Button color="yellow" onClick={e => this.modify()}>수정</Button>
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Form.Group>
                                        {/*  <Form.Group label="반려">*/}
                                        {/*  <Grid.Row>*/}
                                        {/*    <Grid.Col>*/}
                                        {/*      <Form.Textarea>*/}
                                        {/*    </Form.Textarea>*/}
                                        {/*    </Grid.Col>*/}
                                        {/*    <Grid.Col auto>*/}
                                        {/*      <Button color="pink" onClick={e=>this.modify()}>반려</Button>*/}
                                        {/*    </Grid.Col>*/}
                                        {/*  </Grid.Row>*/}
                                        {/*</Form.Group>*/}
                                        <Form.Group>
                                            <Grid.Row>
                                                <Grid.Col>
                                                    <Button.List align="right">
                                                        <Button
                                                            color="danger"
                                                            onClick={() => this.isImageValid(false)}>
                                                            검수불가</Button>
                                                        <Button
                                                            color="success"
                                                            onClick={() => this.isImageValid(true)}>
                                                            검수완료</Button>
                                                    </Button.List>
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button.List>
                                    <Button color="secondary" onClick={e => this.prevPage(e)}>이전</Button>
                                    <Button color="secondary" onClick={e => this.nextPage(e)}>다음</Button>
                                </Button.List>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <KeyboardEventHandler
                    handleKeys={['right']}
                    onKeyEvent={e => this.nextPage()} />
                <KeyboardEventHandler
                    handleKeys={['left']}
                    onKeyEvent={e => this.prevPage()} />
            </Page.Content>
        )
    }

}

// export default InspectionEdit_ipixel


const mapStateToProps = state => {
    return {
        auth: state.auth,
        list: state.list
    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_images_for_inspection_detail: (image_id) => {
            return dispatch(projects.get_images_for_inspection_detail(image_id))
        },
        change_images_for_inspection_detail: (data) => {
            return dispatch(projects.change_images_for_inspection_detail(data))
        },
        change_survey_for_inspection_detail: (data) => {
            return dispatch(projects.change_survey_for_inspection_detail(data))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(InspectionEdit_ipixel);