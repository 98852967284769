import React from 'react';
import {connect} from "react-redux";
import {projects} from "../../actions";
import Cropper from "react-cropper";
import ScrollArea from "react-scrollbar";
import {isMobile} from 'react-device-detect';
import Modal from 'react-modal';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {Header} from "tabler-react"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


class LetterInspectionTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            img: '',
            cropResult: '',
            letter_data: [],
            work_sub: '',
            pressed: false,
            selected: '',
            modalIsOpen: false,
            reasonSelect: '',
            reasonText: '',
            labeled_by: {
            },
            previous_work: 0,
        };
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this._handleOptionChange = this._handleOptionChange.bind(this);
        this._handleOptionChange2 = this._handleOptionChange2.bind(this);
    }

    componentDidMount() {
        let inspection = true;
        this.props.get_random_letter_labeling(inspection).then(response => {
            console.log(response);
            this.setState({
                labeled_by: response.labeled_by,
                letter_data: response.letter_data,
                img: response.img,
                work_sub_id: response.work_sub_id,
                work_sub: response.work_sub,
                previous_work: response.previous_work
            })
        });
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    _crop() {

    }

    _click(result) {
        // this.refs.hiddenCropper.setData(
        //     this.state.work_sub
        // );
        console.log(result);
        // this.refs.cropper.zoomTo(1);
        // result.scaleX = 2;
        // result.scaleY = 2;
        if (!this.state.pressed) {

            if (result.x > 1000) { // 오
                let zoom = 3;
                let x_move = zoom * 1.5 * 100 - (result.x - 500);
                let y_move = zoom * 1.5 * 100 - (result.y + 100);
                this.refs.cropper.zoom(zoom);
                this.refs.cropper.move(x_move, y_move);
            } else { // 왼
                let zoom = 3;
                let x_move = zoom * 1.5 * 100;
                let y_move = zoom * 1.5 * 100;
                this.refs.cropper.zoom(zoom);
                this.refs.cropper.move(x_move, y_move);
            }


            this.setState({pressed: true, selected: result})
        }
        console.log(result);
        this.refs.cropper.setData(
            result
        );

        // this.refs.cropper.zoom(-1);
        // this.setState({
        //     // showCropper: true,
        //     cropResult: this.refs.hiddenCropper.getCroppedCanvas().toDataURL(),
        //     // currentResult: result,
        //     // labelingLetter : this.removeBlank(result.answer),
        //     // add_text_input : this.removeBlank(result.answer)[this.state.labelNumber],
        // });
        // console.log(result)

        // x 730 y 634 zoom 3
        // x 1326 y 1111 zoom 3 move -1000

    }

    _handleOptionChange(changeEvent) {
        this.setState({
            reasonSelect: changeEvent.target.value
        })
    }

    _handleOptionChange2(changeEvent) {
        this.setState({
            reasonText: changeEvent.target.value
        })
    }


    _handleInspect(type) {
        let letter_ids = [];
        this.state.letter_data.map((letter) =>
            letter_ids.push(letter.id)
        );
        let data;
        if (type === 'wrong_image') {
            data = {
                type: 'wrong_image',
                letter_ids: letter_ids
            };
            window.location="/post-cube/inspection/letter/"
        } else if (type === 'modify') {
            letter_ids = [this.state.selected.id, this.refs.cropper.getData()];
            data = {
                type: 'modify',
                letter_ids: letter_ids
            };
            alert('수정됨')
        } else if (type === 'confirm') {
            data = {
                type: 'confirm',
                letter_ids: letter_ids
            };
            window.location = "/post-cube/inspection/letter/"

        } else if (type === 'not_workable2') {
            this.closeModal();
            console.log(letter_ids);
            data = {
                type: 'not_workable',
                letter_ids: letter_ids,
                reasonSelect: this.state.reasonSelect,
                reasonText: this.state.reasonText
            };
            this.setState({reasonText: "", reasonSelect: ""});
            window.location = "/post-cube/inspection/letter/"

        }
        console.log(data);
        this.props.inspect_confirm(data).then(res => console.log(res));
    }
    previous_page = (previous_work)=>{
        this.props.get_previous_letter_labeling(previous_work).then(response=>
            //console.log(res)
            this.setState({
                labeled_by: response.labeled_by,
                letter_data: response.letter_data,
                img: response.img,
                work_sub_id: response.work_sub_id,
                work_sub: response.work_sub,
                previous_work: response.previous_work
            })
        )
    }
    render() {
        let itemElements =
            this.state.letter_data.map((letter) =>
                <button onClick={() => this._click(letter)}
                        className="btn btn-info m-3">{letter.answer}</button>
            );
        const {labeled_by} = this.state
        return (
            <KeyboardEventHandler
                handleKeys={['right']}
                onKeyEvent={(key, e) => alert(`do something upon keydown event of ${key}`)}>
                <div style={isMobile ? {marginLeft: 20} : {marginLeft: 100}} className='mt-5'>
                <Header.H2>
                    {`작업자 : ${labeled_by.user}
                    총 작업수 : ${labeled_by.user_total_works}
                    반려 작업수 : ${labeled_by.user_not_workable}`}
                </Header.H2>
                    <div className={'row'}>
                        <Cropper
                            dragMode={'move'}
                            autoCropArea={0.001}
                            preview=".preview"
                            className="img-container"
                            ref='cropper'
                            src={this.state.img}
                            style={{height: '95%', width: '100%', maxWidth: 800,}}
                            crop={this._crop.bind(this)}/>
                        <div className={'column ml-3 mr-3'}
                             style={{alignContent: 'flex-end', maxWidth: 60, alignSelf: 'flex-end'}}>
                            <button className="btn btn-success" onClick={() => this.refs.cropper.zoom(0.1)}>+</button>
                            <button className="btn btn-success mt-3 mb-3" onClick={() => this.refs.cropper.zoom(-0.1)}>-
                            </button>

                        </div>
                        <div className={'column'}>
                            <div className="preview"
                                 style={
                                     isMobile ?
                                         {width: 250, height: 100, overflow: "hidden"} :
                                         {width: 300, height: 100, overflow: "hidden", marginLeft: 60}}></div>
                            <ScrollArea
                                style={{width: 250, maxHeight: 600}}
                                className="area mt-5"
                                contentClassName="content d-inline-block"
                                horizontalScrollbarStyle={{borderRadius: 5}}
                                smoothScrolling={true}
                                minScrollSize={40}
                                swapWheelAxes={true}>
                                {itemElements}
                            </ScrollArea>
                        </div>
                    </div>
                    <div>
                        <div className={'row mt-5'}>
                            <button onClick={() => this.refs.cropper.setDragMode('move')}
                                    className="btn btn-success mr-3">
                                <img style={{width: 15}}
                                     src={'https://cdn2.iconfinder.com/data/icons/music-player-icons-filled/55/Expand_2-512.png'}/>
                            </button>
                            <button onClick={() => this.refs.cropper.setDragMode('crop')} className="btn btn-success">
                                <img
                                    style={{width: 15}}
                                    src={'https://cdn2.iconfinder.com/data/icons/social-messaging-productivity-1/128/photo-crop-512.png'}/>
                            </button>
                        </div>
                        <div className={'row mt-2 mb-5'}>
                            <button onClick={() => this._handleInspect('wrong_image')}
                                    style={{width: '20%', backgroundColor: 'grey', color: 'white'}}
                                    className="btn mr-3">불가
                            </button>
                            <button onClick={this.openModal} style={{width: '20%'}}
                                    className="btn btn-danger mr-3">반려
                            </button>
                            <button onClick={() => this._handleInspect('modify')} style={{width: '20%'}}
                                    className="btn btn-warning mr-3">수정
                            </button>
                            <button onClick={() => this._handleInspect('confirm')} style={{width: '20%'}}
                                    className="btn btn-success">완료
                            </button>
                        </div>
                        <button onClick={e=>this.previous_page(this.state.previous_work)}
                                    //style={{width: '15%'}}
                                    className="btn mr-3">이전 페이지
                            </button>
                    </div>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <div className={'row'}>
                            <h2 ref={subtitle => this.subtitle = subtitle}>반려 사유</h2>
                            <button className={'btn btn-warning ml-5'} onClick={this.closeModal}>x</button>
                        </div>
                        <form>
                            <div className="radio">
                                <label>
                                    <input type="radio" value="1"
                                           checked={this.state.reasonSelect === '1'}
                                           onChange={this._handleOptionChange}/>
                                    영역을 잘못 지정함
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" value="2"
                                           checked={this.state.reasonSelect === '2'}
                                           onChange={this._handleOptionChange}/>
                                    글자를 잘못 입력함
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" value="3"
                                           checked={this.state.reasonSelect === '3'}
                                           onChange={this._handleOptionChange}/>
                                    기타
                                </label>
                            </div>
                            {
                                this.state.reasonSelect === '3' ?
                                    <div>
                                        <input type="text" className={'form-control'}
                                               onChange={this._handleOptionChange2}/>
                                    </div> : null
                            }
                        </form>
                        <button onClick={() => this._handleInspect('not_workable2')}
                                className="btn btn-danger mr-3">반려
                        </button>
                    </Modal>

                </div>
            </KeyboardEventHandler>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submit_labeled_word_data: (image_pk, answer) => {
            return dispatch(projects.submit_labeled_word_data(image_pk, answer))
        },
        get_random_letter_labeling: (inspection) => {
            return dispatch(projects.get_random_letter_labeling(inspection))
        },
        get_previous_letter_labeling: (previous_work) =>{
            return dispatch(projects.get_previous_letter_labeling(previous_work))
        },
        inspect_confirm: (data) => {
            return dispatch(projects.inspect_confirm(data))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LetterInspectionTest);