const initialState = {
  agree: sessionStorage.getItem("agree")
};

export default function policy(state = initialState, action) {
  switch (action.type) {
      case 'AGREE_POLICY':
          sessionStorage.setItem("agree", true);
        return {...state, agree: true}
      case 'DISAGREE_POLICY':
          localStorage.removeItem("agree", false);
        return {...state, agree: false}
      default:
          return state;
  }
}