import React from 'react';
import { projects } from "../../actions";
import { connect } from "react-redux";
import Cropper from "react-cropper";
import CustomCropper from "../../components/CustomCropper"
import { Redirect } from 'react-router-dom';
import { Button } from "tabler-react";

//한글자 레이블링 보여주는 화면

class LetterLabeling2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            img: '',
            work_sub_id: '',
            arrAPI: []
        }
    }

    componentDidMount() {
        this.callAPI()
    }
    callAPI = async (cnt) =>{
        const {arrAPI} = this.state
        if (!!this.props.auth.user) {
        await this.props.get_random_letter_labeling().then(response => {
                arrAPI.push(response)
                this.setState({
                    arrAPI: arrAPI
                })
            });
        }
        if (this.state.arrAPI.length > 5) {
        } else if (this.state.arrAPI.length === 1) {
            this.refresh()
        } else {
            await this.callAPI(this.state.arrAPI.length)
        }
        //console.log(this.state.arrAPI.length)
    }
    refresh = () =>{
        const {arrAPI} = this.state
        this.setState({
            results: arrAPI[0].work_sub,
            img: arrAPI[0].img,
            work_sub_id: arrAPI[0].work_sub_id
        })
        this.callAPI(this.state.arrAPI.length)
    }
    _getdata = async (datas) => {
        const { arrAPI } = this.state
        datas.map(data => {
            delete data.img
            data.data.x += this.state.results.x;
            data.data.y += this.state.results.y;
            data.data.rotate = this.state.results.rotate
        });
        if (this.state.arrAPI.length === 1) {
            alert("LENGTH")
            arrAPI.shift()
            this.setState({
                showCropper:false
            })
            window.location = '/letter-labeling/'
        } else {
            arrAPI.shift()
            this.refresh()
            this.setState({
                showCropper:false
            })
        }
        await this.props.submit_labeled_word_data(this.state.work_sub_id, datas)
        //window.location = '/letter-labeling/'
    };

    _click(result) {
        this.refs.hiddenCropper.setData(
            result
        )
        this.setState({
            showCropper: true,
            cropResult: this.refs.hiddenCropper.getCroppedCanvas().toDataURL(),
            currentResult: result
            // labelingLetter : this.removeBlank(result.answer),
            // add_text_input : this.removeBlank(result.answer)[this.state.labelNumber],
        })
    }

    render() {
        return (
            !!this.props.auth.user ?
                this.state.results.length === 0 ?
                    "LOADING..."
                    : <div className={'m-5'} style={{ backgroundColor: '#434343' }}>
                        {
                            this.state.results ? //만약 data를 받지않았을 경우(레이블링 작업 페이지) 버튼을 보여주지 않음
                                <Button onClick={() => this._click(this.state.results)}
                                    className="btn btn-info m-3">{this.state.results.answer}</Button>
                                : null
                        }
                        <Cropper
                            autoCropArea="1"
                            className="img-container"
                            ref='hiddenCropper'
                            preview='.preview'
                            src={this.state.img}
                            style={{ height: '80%', width: '80%', display: 'none' }}
                        />
                        {
                            this.state.showCropper ?
                                <CustomCropper img={this.state.cropResult} data={this.state.results}
                                    _getdata={this._getdata} />
                                : null
                        }
                    </div>
                : <Redirect to="/" />)
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submit_labeled_word_data: (image_pk, answer) => {
            return dispatch(projects.submit_labeled_word_data(image_pk, answer))
        },
        get_random_letter_labeling: () => {
            return dispatch(projects.get_random_letter_labeling())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LetterLabeling2);