import React from 'react';
import {Card, Button} from "tabler-react"
import {policy} from "../actions"
import {connect} from "react-redux";

class Policy extends React.Component {
  constructor(props){
    super(props)
    this.state={}
  }
  agree = () =>{
    this.props.agree_policy()
  }
  disagree = () =>{
    this.props.disagree_policy()
  }
    render() {
        return (
            <Card title = "프로젝트 약관 동의">
              <Card.Body>
              본 프로젝트를 수행하며 보여지는 데이터는 외부로 유출하지 않을 것이며, 작업한 결과물의 소유권은 (주)데이터리퍼블릭에 있음을 동의합니다.​
              </Card.Body>
              <Card.Footer>
                <Button.List align="right">
                  <Button onClick={e=> this.disagree()}>동의하지않습니다.</Button>
                  <Button onClick={e=> this.agree()} color="primary">동의합니다.</Button>
                </Button.List>
              </Card.Footer>
            </Card>
          )
    }
}

const mapStateToProps = state => {
  return {
      auth: state.auth,
      policy: state.policy
  }
};

const mapDispatchToProps = dispatch => {
  return {
    agree_policy: ()=>{
      return dispatch(policy.agree_policy())
    },
    disagree_policy: ()=>{
      return dispatch(policy.disagree_policy())
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Policy)