import * as React from "react";

import {
    Page,
    Avatar,
    Icon,
    Grid,
    Card,
    Text,
    Table,
    Progress,
    colors,
    Dropdown,
    StampCard,
    StatsCard,
    Badge,
} from "tabler-react";

import C3Chart from "react-c3js";
import 'c3/c3.css';
import {auth} from "../actions";
import {connect} from "react-redux";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            home: '',
            workerRankingArray: [
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    total_works: 60093,
                    points: 9098000
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    total_works: 60093,
                    points: 9098000
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    total_works: 60093,
                    points: 9098000
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    total_works: 60093,
                    points: 9098000
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    total_works: 60093,
                    points: 9098000
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    total_works: 60093,
                    points: 9098000
                },
            ],
            managers: [
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    registered_date: '2019-08-02',
                    inspection_rate: 56,
                    last_login: '5일전',
                    total_inspection: 8.5
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    registered_date: '2019-08-02',
                    inspection_rate: 56,
                    last_login: '6달전',
                    total_inspection: 8.5
                },
                {
                    profile_image: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
                    name: '안성빈',
                    registered_date: '2019-08-02',
                    inspection_rate: 56,
                    last_login: '7분전',
                    total_inspection: 8.5
                },

            ]
        }
    }

    componentDidMount() {
        this.props.manager_home().then(res => {
            console.log(res);
            this.setState({home: res});
        })
    }


    render() {
        return (

            <Page.Content title="대시 보드">
                <Grid.Row cards={true}>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard layout={1} movement={0} total={this.state.home.today} label="오늘의 작업수"/>
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard
                            layout={1}
                            movement={0}
                            total={this.state.home.week}
                            label="이번주 작업수"
                        />
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard layout={1} movement={0} total={this.state.home.month} label="이달의 작업수"/>
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard
                            layout={1} movement={0} total={this.state.home.users} label="회원수"/>
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard layout={1} movement={0} total={this.state.home.total} label="총 작업량"
                        />
                    </Grid.Col>
                    <Grid.Col width={6} sm={4} lg={2}>
                        <StatsCard layout={1} movement={0} total={this.state.home.total_price} label="지급해야할 금액"/>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    작업량
                                </Card.Title>
                            </Card.Header>
                            {
                                this.state.home=== '' ? null :
                                    <C3Chart
                                        style={{height: "10rem"}}
                                        data={{
                                            columns: [
                                                // each columns data
                                                this.state.home.work_data_column,
                                            ],
                                            type: "area", // default type of chart
                                            groups: [["data1"]],
                                            colors: {
                                                data1: colors["blue"],
                                            },
                                            names: {
                                                // name of each serie
                                                data1: "총 작업량",
                                            },
                                        }}
                                        axis={{
                                            y: {
                                                padding: {
                                                    bottom: 0,
                                                },
                                                show: false,
                                                tick: {
                                                    outer: false,
                                                },
                                            },
                                            x: {
                                                padding: {
                                                    left: 0,
                                                    right: 0,
                                                },
                                                show: false,
                                            },
                                        }}
                                        legend={{
                                            position: "inset",
                                            padding: 0,
                                            inset: {
                                                anchor: "top-left",
                                                x: 20,
                                                y: 8,
                                            },
                                        }}
                                        tooltip={{
                                            format: {
                                                title: function (x) {
                                                    return "";
                                                },
                                            },
                                        }}
                                        padding={{
                                            bottom: 0,
                                            left: -1,
                                            right: -1,
                                        }}
                                        point={{
                                            show: false,
                                        }}
                                    />
                            }

                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                className="table-vcenter"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColHeader>날짜</Table.ColHeader>
                                        <Table.ColHeader>총 작업량</Table.ColHeader>
                                        <Table.ColHeader>포인트</Table.ColHeader>
                                        <Table.ColHeader/>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>

                                    {
                                        this.state.home === '' ? null :
                                            this.state.home.work_list.map((worker, id) =>
                                                <Table.Row key={id}>
                                                    <Table.Col>{worker.created_at}</Table.Col>
                                                    <Table.Col>{worker.total.toLocaleString()} 개</Table.Col>
                                                    <Table.Col
                                                        className="text-nowrap">{worker.points.toLocaleString()} P</Table.Col>
                                                    <Table.Col className="w-1">
                                                        <Icon link={true} name="none"/>
                                                    </Table.Col>
                                                </Table.Row>
                                            )
                                    }
                                </Table.Body>
                            </Table>
                        </Card>
                    </Grid.Col>

                    <Grid.Col md={6}>

                        <Grid.Row>
                            <Grid.Col sm={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>작업율 대비 검수율</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {
                                            this.state.home === '' ? null :
                                                <C3Chart
                                                    style={{height: "12rem"}}
                                                    data={{
                                                        columns: this.state.home.approved_data_column,
                                                        type: "donut", // default type of chart
                                                        colors: {
                                                            data1: colors["green"],
                                                            data2: colors["green-light"],
                                                        },
                                                        names: {
                                                            // name of each serie
                                                            data1: "완료",
                                                            data2: "미완료",
                                                        },
                                                    }}
                                                    legend={{
                                                        show: false, //hide legend
                                                    }}
                                                    padding={{
                                                        bottom: 0,
                                                        top: 0,
                                                    }}
                                                />
                                        }
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                            <Grid.Col sm={6}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>작업자 연령대</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <C3Chart
                                            style={{height: "12rem"}}
                                            data={{
                                                columns: [
                                                    // each columns data
                                                    ["data1", 63],
                                                    ["data2", 44],
                                                    ["data3", 12],
                                                    ["data4", 14],
                                                ],
                                                type: "pie", // default type of chart
                                                colors: {
                                                    data1: colors["blue-darker"],
                                                    data2: colors["blue"],
                                                    data3: colors["blue-light"],
                                                    data4: colors["blue-lighter"],
                                                },
                                                names: {
                                                    // name of each serie
                                                    data1: "20대",
                                                    data2: "30대",
                                                    data3: "40대",
                                                    data4: "50대",
                                                },
                                            }}
                                            legend={{
                                                show: false, //hide legend
                                            }}
                                            padding={{
                                                bottom: 0,
                                                top: 0,
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </Grid.Col>
                            {/*<Grid.Col sm={6}>*/}
                            {/*    <ProgressCard*/}
                            {/*        header="총 이미지 수"*/}
                            {/*        content="62만개"*/}
                            {/*        progressColor="red"*/}
                            {/*        progressWidth={28}*/}
                            {/*    />*/}
                            {/*</Grid.Col>*/}
                            {/*<Grid.Col sm={6}>*/}
                            {/*    <ProgressCard*/}
                            {/*        header="오늘 지급한 현금"*/}
                            {/*        content="652만원"*/}
                            {/*        progressColor="green"*/}
                            {/*        progressWidth={84}*/}
                            {/*    />*/}
                            {/*</Grid.Col>*/}
                            {/*<Grid.Col sm={6}>*/}
                            {/*    <ProgressCard*/}
                            {/*        header="현재 작업하고 있는 사용자"*/}
                            {/*        content="76명"*/}
                            {/*        progressColor="yellow"*/}
                            {/*        progressWidth={34}*/}
                            {/*    />*/}
                            {/*</Grid.Col>*/}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col sm={6} lg={3}>
                        <StampCard
                            color="blue"
                            icon="dollar-sign"
                            header={
                                <a href="/payment/">
                                    132 <small>환전 요청</small>
                                </a>
                            }
                            footer={"총 563만원 환전 요청 승인 대기 중"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={6} lg={3}>
                        <StampCard
                            color="green"
                            icon="shopping-cart"
                            header={
                                <a href="#">
                                    78 <small>기업 문의</small>
                                </a>
                            }
                            footer={"32개 답변 대기 중"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={6} lg={3}>
                        <StampCard
                            color="red"
                            icon="users"
                            header={
                                <a href="#">
                                    1,352 <small>새 방문자</small>
                                </a>
                            }
                            footer={"163명 회원가입"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={6} lg={3}>
                        <StampCard
                            color="yellow"
                            icon="message-square"
                            header={
                                <a href="#">
                                    132개 <small>문의</small>
                                </a>
                            }
                            footer={"16개 답변 대기중"}
                        />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row cards deck>
                    <Grid.Col width={12}>
                        <Card>
                            <Table
                                responsive
                                highlightRowOnHover
                                hasOutline
                                verticalAlign="center"
                                cards
                                className="text-nowrap"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColHeader alignContent="center" className="w-1">
                                            <i className="icon-people"/>
                                        </Table.ColHeader>
                                        <Table.ColHeader>매니저</Table.ColHeader>
                                        <Table.ColHeader>검수율</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">
                                            등급
                                        </Table.ColHeader>
                                        <Table.ColHeader>활동</Table.ColHeader>
                                        <Table.ColHeader alignContent="center">
                                            총 검수
                                        </Table.ColHeader>
                                        <Table.ColHeader alignContent="center">
                                            <i className="icon-settings"/>
                                        </Table.ColHeader>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>

                                    {
                                        this.state.managers.map((manager, id) =>
                                            <Table.Row>
                                                <Table.Col alignContent="center">
                                                    <Avatar
                                                        imageURL={manager.profile_image}
                                                        className="d-block"
                                                        status="green"
                                                    />
                                                </Table.Col>
                                                <Table.Col>
                                                    <div>{manager.name}</div>
                                                    <Text size="sm" muted>
                                                        가입일: {manager.registered_date}
                                                    </Text>
                                                </Table.Col>
                                                <Table.Col>
                                                    <div className="clearfix">
                                                        <div className="float-left">
                                                            <strong>{manager.inspection_rate}%</strong>
                                                        </div>
                                                    </div>
                                                    <Progress size="xs">
                                                        <Progress.Bar color="yellow" width={manager.inspection_rate}/>
                                                    </Progress>
                                                </Table.Col>
                                                <Table.Col alignContent="center">
                                                    <Icon payment name="visa"/>
                                                </Table.Col>
                                                <Table.Col>
                                                    <Text size="sm" muted>
                                                        마지막 로그인
                                                    </Text>
                                                    <div>{manager.last_login}</div>
                                                </Table.Col>
                                                <Table.Col
                                                    alignContent="center">{manager.total_inspection} 천개</Table.Col>
                                                <Table.Col alignContent="center">
                                                    <Dropdown
                                                        trigger={
                                                            <Dropdown.Trigger
                                                                icon="more-vertical"
                                                                toggle={false}
                                                            />
                                                        }
                                                        position="right"
                                                        items={
                                                            <React.Fragment>
                                                                <Dropdown.Item icon="tag">Action </Dropdown.Item>
                                                                <Dropdown.Item icon="edit-2">
                                                                    Another action{" "}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item icon="message-square">
                                                                    Something else here
                                                                </Dropdown.Item>
                                                                <Dropdown.ItemDivider/>
                                                                <Dropdown.Item icon="link">
                                                                    {" "}
                                                                    Separated link
                                                                </Dropdown.Item>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </Table.Col>
                                            </Table.Row>
                                        )
                                    }


                                </Table.Body>
                            </Table>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col sm={6} lg={4}>
                        <Card title="PC 및 모바일 사용율">
                            <Table className="card-table">
                                <Table.Row>
                                    <Table.Col>
                                        <Icon prefix="fa" name="chrome" className="text-muted"/>
                                    </Table.Col>
                                    <Table.Col>PC</Table.Col>
                                    <Table.Col className="text-right">
                                        <Text RootComponent="span" muted>
                                            77%
                                        </Text>
                                    </Table.Col>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Col>
                                        <Icon prefix="fa" name="chrome" className="text-muted"/>
                                    </Table.Col>
                                    <Table.Col>모바일</Table.Col>
                                    <Table.Col className="text-right">
                                        <Text RootComponent="span" muted>
                                            23%
                                        </Text>
                                    </Table.Col>
                                </Table.Row>
                            </Table>
                        </Card>
                    </Grid.Col>
                    <Grid.Col sm={6} lg={4}>
                        <Card title="기업 프로젝트 작업율">
                            <Table cards>
                                <Table.Row>
                                    <Table.Col>포스트큐브</Table.Col>
                                    <Table.Col alignContent="right">
                                        <Badge color="default">57%</Badge>
                                    </Table.Col>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Col>아이픽셀</Table.Col>
                                    <Table.Col alignContent="right">
                                        <Badge color="default">62%</Badge>
                                    </Table.Col>
                                </Table.Row>

                            </Table>
                        </Card>
                    </Grid.Col>
                    <Grid.Col md={6} lg={4}>
                        <Card title="메시지">
                            <Card.Body>
                                <ul className="list-unstyled list-separated">
                                    <li className="list-separated-item">

                                        <Grid.Row className="align-items-center">
                                            <Grid.Col auto>
                                                <Avatar
                                                    size="md"
                                                    className="d-block"
                                                    imageURL="https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891"
                                                />
                                            </Grid.Col>
                                            <Grid.Col>
                                                <div>
                                                    <a className="text-inherit" href="#">
                                                        안성빈
                                                    </a>
                                                </div>
                                                <Text.Small muted className="d-block item-except h-1x">
                                                    wd1kr1@gmail.com
                                                </Text.Small>
                                            </Grid.Col>
                                            <Grid.Col auto>
                                                <Dropdown
                                                    trigger={
                                                        <Dropdown.Trigger
                                                            icon="more-vertical"
                                                            toggle={false}
                                                        />
                                                    }
                                                    position="right"
                                                    items={
                                                        <React.Fragment>
                                                            <Dropdown.Item icon="tag">Action </Dropdown.Item>
                                                            <Dropdown.Item icon="edit-2">
                                                                {" "}
                                                                Another action{" "}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item icon="message-square">
                                                                {" "}
                                                                Something else here
                                                            </Dropdown.Item>
                                                            <Dropdown.ItemDivider/>
                                                            <Dropdown.Item icon="link">
                                                                {" "}
                                                                Separated link
                                                            </Dropdown.Item>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </Grid.Col>
                                        </Grid.Row>


                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                    <Grid.Col md={6} lg={12}>
                        <Grid.Row>
                            <Grid.Col sm={6} lg={3}>
                                <StatsCard
                                    layout={2}
                                    movement={5}
                                    total="90%"
                                    label="아이픽셀 이미지 레이블링"
                                    chart={
                                        <C3Chart
                                            style={{height: "100%"}}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Users online",
                                                },
                                                columns: [["data1", 30, 40, 10, 40, 12, 22, 40]],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return "";
                                                    },
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: false,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#467fcf"],
                                            }}
                                        />
                                    }
                                />
                            </Grid.Col>
                            <Grid.Col sm={6} lg={3}>
                                <StatsCard
                                    layout={2}
                                    movement={-3}
                                    total="95%"
                                    label="포스트큐브 이미지 레이블링"
                                    chart={
                                        <C3Chart
                                            style={{height: "100%"}}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Users online",
                                                },
                                                columns: [["data1", 30, 40, 10, 40, 12, 22, 40]],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return "";
                                                    },
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: false,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#e74c3c"],
                                            }}
                                        />
                                    }
                                />
                            </Grid.Col>
                            <Grid.Col sm={6} lg={3}>
                                <StatsCard
                                    layout={2}
                                    movement={-3}
                                    total="15%"
                                    label="포스트큐브 한글자 이미지 레이블링 갯수"
                                    chart={
                                        <C3Chart
                                            style={{height: "100%"}}
                                            padding={{
                                                bottom: -10,
                                                left: -1,
                                                right: -1,
                                            }}
                                            data={{
                                                names: {
                                                    data1: "Users online",
                                                },
                                                columns: [["data1", 30, 40, 10, 40, 12, 22, 40]],
                                                type: "area",
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                            transition={{
                                                duration: 0,
                                            }}
                                            point={{
                                                show: false,
                                            }}
                                            tooltip={{
                                                format: {
                                                    title: function (x) {
                                                        return "";
                                                    },
                                                },
                                            }}
                                            axis={{
                                                y: {
                                                    padding: {
                                                        bottom: 0,
                                                    },
                                                    show: false,
                                                    tick: {
                                                        outer: false,
                                                    },
                                                },
                                                x: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                    show: false,
                                                },
                                            }}
                                            color={{
                                                pattern: ["#5eba00"],
                                            }}
                                        />
                                    }
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>

        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        manager_home: () => {
            return dispatch(auth.manager_home());
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);

