import React from "react";
import { Page, Grid, Form, Card, Button } from "tabler-react"
import { connect } from "react-redux";
import { projects } from "../../actions";
import { SelectBox } from "../../components/SelectBox"
import Cropper from "react-cropper";
import ReturnModal from "../../components/Labeling/ReturnModal"
class InspectionDetail_ipixel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            image_path: '',
            image_label_list: [{ id: '', answer: '' }],
            showModify: false,
            fixAnswer: '',
            survey: [],
            answer_id: '',
            selected_image: '',
            selected_survey: [],
            msg: '',
            next: '',
            result: '',
            answered_by: '',
            modalIsOpen: false,
            reasonSelect: 1,
            reasonText: "",
            objectType: [],
            related_object: "",
            current_index:-1
        }
    }
    componentDidMount() {
        this.props.get_object_type().then(response =>
            this.setState({
                objectType: response
            })
        );

        this.props.get_images_for_inspection_detail(this.props.match.params.inspection_id).then(
            res => {
                console.log(res)
                this.setState({
                    answered_by: res.answered_by,
                    image_path: 'https://datarepublic-upload-files.s3.ap-northeast-2.amazonaws.com/' + res.image_path,
                    image_label_list: res.image_label_list,
                    survey: res.survey,
                    answer_id: res.answer_id,
                    msg: res.msg
                });
            }
        )
    }

    _changeAnswer2 = async () => {
        let data = {
            type: 'change',
            imageLabel: this.refs.cropper.getData(),
            fixAnswer: '',
            image_id: this.state.selected_image
        };
        console.log(data);
        await this.props.change_images_for_inspection_detail(data)
    };


    _unableToAnswer = () => {
        let data = {
            type: '작업 불가',
            answer_id: this.state.answer_id
        };
        this.props.change_images_for_inspection_detail(data).then(res =>
        // console.log(res)
        {
            window.location.replace(res.next)
        }
        )

    };
    //버튼을 클릭했을때 동작하는 함수
    _click(id) {
        const result = this.state.image_label_list[id]
        const survey = this.state.survey[id]
        this.refs.cropper.setData(
            result
        )
        this.setState({
            current_index:id,
            selected_survey: survey,
            showModify: true,
            selected_image: result.id,
            related_object: result.related_object
        })
    }
    //설문지 변경했을 때 동작하는 함수
    _changeSurveyOption(e, survey_id, key) {
        let survey = this.state.selected_survey;
        survey[key].answer = e.target.value;
        this.setState({
            selected_survey: survey
        });
        let data = {
            survey_id: survey_id,
            answer: e.target.value,
            type: 'change_survey'
        }
        this.props.change_survey_for_inspection_detail(data).then(
            res => {
                console.log(res)
            }
        )
    }

    _getdata = (getData) => {
        console.log(getData)
    };

    _deleteAnswer2 = () => {
        console.log(this.state.selected_image)
        //console.log("???")
        let image_id = this.state.selected_image;
        let data = {
            image_id: image_id,
            type: 'delete'
        };
        this.props.change_images_for_inspection_detail(data)
        //window.location.reload()
    };

    //작업불가
    _wrongImage = () => {
        let image_id = this.state.selected_image;
        let data = {
            answer_id: this.state.answer_id,
            image_id: image_id,
            type: 'wrong_image'
        };
        console.log(data);
        this.props.change_images_for_inspection_detail(data);
        // window.location.reload()
    };

    //반려
    _returnImage = () => {
        let data = {
            answer_id: this.state.answer_id,
            image_id: this.state.selected_image,
            reasonSelect: this.state.reasonSelect,
            reasonText: this.state.reasonText,
            type: 'not_workable'
        };
        //console.log(data);
        this.props.change_images_for_inspection_detail(data);
    };
    _approve = () => {
        let image_id = this.state.selected_image;
        let data = {
            answer_id: this.state.answer_id,
            image_id: image_id,
            type: 'approve'
        };
        console.log(data);
        this.props.change_images_for_inspection_detail(data).then(res =>
            window.location = res.next)
    };
    prevPage = () => {
//        history.go(-1)
    }
    sendData = (type, data) => {
        if (type === "modal") {
            this.setState({
                modalIsOpen: data
            })
        } else if (type === "reasonSelect") {
            this.setState({
                reasonSelect: Number(data),
                reasonText: ""
            })
        } else if (type === "reasonText") {
            this.setState({
                reasonText: data
            })
        } else if (type === "submit") {
            this._returnImage()
        } else {
            alert("SERVER ERROR!!")
        }
    }
    //카테고리 변경
    get_object_type = (category) => {
        const {image_label_list, current_index, selected_image} = this.state
        image_label_list[current_index].related_object = category
        let image_id = selected_image
        let data = {
            type: '카테고리 변경2',
            change_category: category,
            image_id: image_id,
            image_label_list : image_label_list
        };
        this.props.change_images_for_inspection_detail(data)
    };
    render() {
        const { selected_survey } = this.state
        return (
            <Page.Content title="2차 검수 페이지">
                <Grid.Row>
                    <Grid.Col lg={12} sm={12}>
                        <Card title={`작업자 : ${this.state.answered_by}`}>
                            <Card.Body>
                                <Grid.Row>
                                    <Grid.Col className={"col-12"} sm={12} md={7}>
                                        <Cropper
                                            autoCropArea="0.001"
                                            preview=".preview"
                                            className="img-container"
                                            ref='cropper'
                                            src={this.state.image_path}
                                            style={{ height: '95%', width: '100%' }}
                                        //crop={this._crop.bind(this)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col className={"col-12"} sm={12} md={5}>
                                        <Form.Group>
                                            <Button.List className={"mt-3"}>
                                                {
                                                    this.state.image_path &&
                                                    this.state.image_label_list.map((data, key) =>
                                                        <Button key={key}
                                                            color={(this.state.current_index === key)?"success":""}
                                                            onClick={() => this._click(key)}> {data.id} / {data.answer}</Button>
                                                    )
                                                }
                                            </Button.List>
                                        </Form.Group>
                                        {
                                            this.state.related_object &&
                                            <Form.Group>
                                                <SelectBox
                                                    objectOption={this.state.related_object}
                                                    sendData={this.get_object_type}
                                                    data={this.state.objectType} />
                                            </Form.Group>
                                        }
                                        <Form.Group>
                                            {
                                                this.state.showModify &&
                                                <Button.List>
                                                    <Button color="success"
                                                        onClick={this._changeAnswer2}>수정</Button>
                                                    <Button color="danger" onClick={this._deleteAnswer2}>삭제</Button>
                                                    <Button color="grey" onClick={e => this.setState({ modalIsOpen: true })}>
                                                        반려
                                                    </Button>
                                                    <ReturnModal
                                                        modalIsOpen={this.state.modalIsOpen}
                                                        sendData={this.sendData}
                                                        reasonSelect={this.state.reasonSelect} />
                                                    <Button color="dark" onClick={this._wrongImage}>
                                                        작업불가
                                                    </Button>
                                                </Button.List>
                                            }
                                        </Form.Group>
                                        {
                                            selected_survey &&
                                            selected_survey.map((data, key) =>
                                                <Form.Group label={data.question}>
                                                    <Form.Radio
                                                        isInline
                                                        value="예"
                                                        label="예"
                                                        checked={selected_survey[key].answer === "예"}
                                                        onChange={(e) => this._changeSurveyOption(e, data.id, key)} />
                                                    <Form.Radio
                                                        isInline
                                                        value="아니오"
                                                        label="아니오"
                                                        checked={selected_survey[key].answer === "아니오"}
                                                        onChange={(e) => this._changeSurveyOption(e, data.id, key)} />
                                                    <Form.Radio
                                                        isInline value="모름"
                                                        label="모름"
                                                        checked={selected_survey[key].answer === "모름"}
                                                        onChange={(e) => this._changeSurveyOption(e, data.id, key)} />
                                                </Form.Group>
                                            )
                                        }
                                        <Button.List>
                                            <Button onClick={e => this.prevPage()}>
                                                이전 작업
                                            </Button>
                                            <Button color="success" onClick={this._approve}>
                                                검수완료
                                            </Button>
                                        </Button.List>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_object_type: () => {
            return dispatch(projects.get_object_type())
        },
        get_images_for_inspection_detail: (image_id) => {
            return dispatch(projects.get_images_for_inspection_detail(image_id))
        },
        change_images_for_inspection_detail: (data) => {
            return dispatch(projects.change_images_for_inspection_detail(data))
        },
        change_survey_for_inspection_detail: (data) => {
            return dispatch(projects.change_survey_for_inspection_detail(data))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(InspectionDetail_ipixel);