
const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    isLoading: true,
    user: {
        name: '안성빈',
        img: 'https://scontent-icn1-1.xx.fbcdn.net/v/t1.0-9/21032560_111457299548045_2652173574426908762_n.jpg?_nc_cat=104&_nc_oc=AQkDI7wfJ7CxWV_uQ9dY_GA2cA4IoORgI0nMz1ZuUi1ocX-grTNW3LzekSXhJFj6WZY&_nc_ht=scontent-icn1-1.xx&oh=ceea67fe3151d777bf8b80f2b8cf1220&oe=5E136891',
        is_manager: false,
        user_id: ''
    },
    errors: {},
    user_id: '',
};


export default function auth(state = initialState, action) {

    switch (action.type) {

        case 'USER_LOADING':
            return {...state, isLoading: true};

        case 'USER_LOADED':
            console.log(action.user);
            return {...state, isAuthenticated: true, isLoading: false, user: action.user, user_id:action.user.pk};

        case 'LOGIN_SUCCESSFUL':
        case 'REGISTRATION_SUCCESSFUL':
            console.log('success login');
            console.log(action.data);
            localStorage.setItem("token", action.data.token);
            return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null};

        case 'AUTHENTICATION_ERROR':
            localStorage.removeItem("token");
            return {
                ...state, token: null,
                user: null,
                isAuthenticated: false, isLoading: false
            };
        case 'LOGIN_FAILED':
        case 'REGISTRATION_FAILED':
        case 'LOGOUT_SUCCESSFUL':
            localStorage.removeItem("token");
            window.location = "/"
            return {
                ...state, errors: action.data, token: null, user: null,
                isAuthenticated: false, isLoading: false
            };

        default:
            return state;
    }
}
