import React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ScrollArea from 'react-scrollbar';
import CropCard from '../components/CropCard';
import { Grid, Card, Button } from "tabler-react";
import { SelectBox, RadioBox } from "../components/SelectBox"
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { projects } from "../actions";
import { connect } from "react-redux";

class CustomCropper extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState()

    }

    async componentDidMount() { //컴포넌트가 시작될때 받은 img, data props를 state로 옮겨줍니다
        this._setData(this.props)
    }
    async _setData(props) {
        if (props.data) {
            await this.refs.cropper.setData(
                props.data
            );
            this.setState({
                img: props.img,
                data: props.data,
                labelingLetter: this.removeBlank(props.data.answer),
                add_text_input: this.removeBlank(props.data.answer)[this.state.labelNumber],
                question: props.question,
            })
        } else if (props.question) {
            this.setState({
                objectType: props.objectType,
                img: props.img,
                question: props.question,
                questionAnswer: Array(props.question.length).fill(-2),
                objectOption: props.objectOption
            })
        } else {
            this.setState({
                img: this.props.img,
                question: this.props.question,
            })
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState){
    //     if (prevState !== nextProps) {
    //         this._setData(nextProps)
    //     }
    //     return null;
    //   }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.reset();
        this._setData(nextProps)
        // this.setState({
        //     answers : []
        // })
    }
    getInitialState = () => {
        const initialState = {
            data: [],
            images: '',
            user: '',
            answers: [], // front 에서 보여주기 위한 answers
            answer: '',
            add_text_input: '',
            labeling: [],
            labelNumber: 0,
            //labeling_works: [],
            work_completed: false,
            change_text_input: '',
            showChange: false,
            selectedChangeAnswer: '',
            labelingLetter: '',
            question: null,
            questionAnswer: [],
            objectType: [],
            objectOption: ''
        };
        return initialState;
    }
    reset() {
        this.setState(this.getInitialState());
    }
    _crop() {
    }
    removeBlank(str) {
        return str.replace(/ /gi, "")
    }

    _handleAdd = () => {
        const { labelNumber, answers, add_text_input, work_sub_id, labelingLetter, objectOption } = this.state
        let question = this.state.questionAnswer.slice()
        const one = add_text_input ? labelingLetter[labelNumber + 1] : ""   //add_text_input(한글자)이 있으면 다음글자로, 없으면 ""
        let answer = {
            'data': this.refs.cropper.getData(),
            'img': this.refs.cropper.getCroppedCanvas().toDataURL(),
            'answer': add_text_input,
            'work_sub_id': work_sub_id,
        }
        if (question.length) {
            answer = {
                ...answer,
                'question': question,
                'objectOption': objectOption
            }
        }
        console.log(answer)
        answers.push(answer);
        this.setState({
            answers: answers,
            add_text_input: one,
            labelNumber: labelNumber + 1
        });
    };

    _deleteAnswer(item) {
        this.setState(prevState => ({
            answers: prevState.answers.filter(el => el !== item)
        }));
    };

    _changeAnswer(item) {
        let answers = this.state.answers;
        let selectedAnswer = answers.filter(el => el === item);
        this.setState({
            showChange: true,
            selectedChangeAnswer: selectedAnswer[0]
        });
    };

    _handleSubmit = async() => {
        const {answers} = this.state
        console.log(answers)
        answers.type="submit"
        await this.props._getdata(this.state.answers)
        await this.setState({
            showCropper: false,
            results: []
        })
    };

    _completeChangeAnswer = () => {
        let selectedChangeAnswer = this.state.selectedChangeAnswer;
        let prevAnswers = this.state.answers;
        prevAnswers.map(el => {
            if (el === selectedChangeAnswer) {
                el.answer = this.state.change_text_input
            }
        }
        );
        this.setState({
            answers: prevAnswers,
            change_text_input: '',
            showChange: false
        }
        );
    };
    _wrongImage = async() => {
        const reasonText = prompt("작업불가 사유를 입력해주세요.")
        let data = {
            reasonText: reasonText,
            type: 'wrong_image'
        }
        this.props._getdata(data)
    }

    _onChange(e) {
        console.log(e.target.value);
        this.setState({
            add_text_input: e.target.value
        })
    }

    _call() {
        console.log(this.props.data)
        this.refs.cropper.setData(
            this.props.data
        )
    }
    get_answer = (val) => {
        this.setState({
            questionAnswer: val
        })
    }
    get_object_type = (val) => {
        this.setState({
            objectOption: val
        })
    };
    render() {
        //       let KeyIntroduceModal = <CustomModal title={"단축키 안내"} body={<KeyIntroduce />} modalName={"KeyIntroduceModal"} />;
        // let AddWorkModals = <CustomModal title={"작업 추가하기"} body={<AddWorkModal labeling={this.state.labeling} />}
        //     modalName={"AddWorkModal"} />;
        let itemElements = []

        this.state.answers.map((item, index) =>
            itemElements.push(
                <div key={index} className="ml-5" style={{ width: '13rem', display: "inline-block" }}>
                    <Button.List>
                        <Button color="success" onClick={() => this._changeAnswer(item)}>수정</Button>
                        <Button color="danger" onClick={() => this._deleteAnswer(item)}>삭제</Button>
                    </Button.List>
                    <CropCard style={{ marginTop: 0 }} src={item.img} alt={index} answer={item.answer}
                        labeling={item.labeling} />
                </div>
            )
        );

        itemElements.reverse();


        return (
            <React.Fragment key={this.props.img}>
                <Grid.Row>
                    <Grid.Col className="col-12" lg={6} sm={12}>
                        <Cropper
                            autoCropArea={1}
                            className="img-container"
                            ref='cropper'
                            style={{ height: '100%' }}
                            preview='.preview'
                            src={this.state.img}
                        //crop={this._crop.bind(this)}
                        />
                    </Grid.Col>
                    {
                        this.state.question &&
                        <Grid.Col className="col-12" lg={3} sm={12}>
                            <Card p={3}>
                                <Card.Body>
                                    <RadioBox
                                        data={this.state.question}
                                        questionAnswer={this.state.questionAnswer}
                                        sendData={this.get_answer} />
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                    }
                    <Grid.Col lg={3} sm={12}>
                        <div className="preview"
                            style={{ height: 150, backgroundColor: '#222', overflow: 'hidden' }}></div>
                        <div className="column">
                            <div>{
                                !(this.state.objectType.length === 0) ?
                                    <SelectBox
                                        objectOption={this.props.objectOption}
                                        sendData={this.get_object_type}
                                        data={this.state.objectType} />
                                    :
                                    <input className="form-control" type="text" id="addText"
                                        value={this.state.add_text_input}
                                        onChange={e => this._onChange(e)} />
                            }
                                <p>{this.state.labelNumber}/{this.state.labelingLetter ? this.state.labelingLetter.length : this.state.labelingLetter} {this.state.labeling[this.state.labelNumber]}</p>
                                <div>
                                    {
                                        this.state.showChange &&
                                        <div className="mt-3 ml-2">
                                            <p>수정하실 정답을 입력해주세요 <Button
                                                className="btn btn-success ml-1"
                                                onClick={this._completeChangeAnswer}>완료</Button></p>
                                            <input className="form-control" type="text" id="changeText"
                                                value={this.state.change_text_input}
                                                onChange={e => this.setState({ change_text_input: e.target.value })} />
                                        </div>
                                    }
                                </div>
                                <Button.List>
                                    <Button block color="primary"
                                        onClick={this._handleAdd}
                                        disabled={this.state.question && this.state.questionAnswer.indexOf(-2) !== -1}
                                    >이미지 자르기</Button>
                                    <Button block color="warning"
                                        className="mb-8"
                                        onClick={() => this._handleSubmit()}
                                        disabled={!this.state.answers.length}
                                    >완료
                                </Button>
                                    <Button onClick={e => this._wrongImage()} color="dark" block>작업불가</Button>
                                </Button.List>
                            </div>
                        </div>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col lg={12} sm={12}>
                        <div style={{
                            textAlign: 'center',
                            backgroundColor: '#ced4da',
                            color: '#495057',
                            height: 30,
                            padding: 5,
                            borderRadius: 5
                        }} className="mt-2">
                            자른 이미지 - {this.state.img !== undefined ?
                                this.state.img.split('/')[5] : null}
                        </div>
                        <ScrollArea
                            style={{ width: '100%', maxHeight: 600, whiteSpace: "nowrap" }}
                            className="area mt-5"
                            contentClassName="content d-inline-block"
                            horizontalScrollbarStyle={{ borderRadius: 5 }}
                            smoothScrolling={true}
                            minScrollSize={40}
                            swapWheelAxes={true}>
                            {itemElements}
                        </ScrollArea>
                    </Grid.Col>
                </Grid.Row>
                <KeyboardEventHandler
                    handleKeys={['ctrl+enter']}
                    onKeyEvent={this._handleAdd} />
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        get_image_multiple_choice_project: (object_type) => {
            return dispatch(projects.get_image_multiple_choice_project(object_type))
        },
        submit_image_multiple_choice_project: (data) => {
            return dispatch(projects.submit_image_multiple_choice_project(data))
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomCropper)