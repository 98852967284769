import React from 'react';
import {Card, Page, Grid, Button} from "tabler-react";


class Payment extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
              }
  }
  render() {
      return (
          <Page.Content title="적립금 관리">
              <Card title="적립금 신청">
                <Card.Body>
                  <Grid.Row>
                      <Grid.Col>
                      {"김수민"}님의 잔액 적립금은 {10000}원입니다.
                      </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                      <Grid.Col>
                          적립금은 5000원부터 신청 가능힙니다.
                      </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                <Card.Footer className="text-right">
                    <Button>적립금 신청</Button>
                </Card.Footer>
              </Card>
          </Page.Content>
      )
  }
}

export default Payment;

