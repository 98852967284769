import React from "react"
import { BlogCard, Grid, Page, Table, Text } from "tabler-react";
import { Link } from "react-router-dom";
import { projects } from "../../actions";
import { connect } from "react-redux";

class MenuSelect_ipixel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      datas: [
        {
          title: "이미지 업로드하기",
          postUrl: "/ipixel/upload/",
          description: "콜라캔, 스프라이트, 손 등의 사진과 그림을 업로드 할 수 있습니다.",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2017/07/03/11/10/ebook-2467267_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/upload/",
          is_manager_only: false
        },
        {
          title: "이미지 레이블링 작업하기",
          postUrl: "/ipixel/labeling/",
          description: "이미지를 자를 수 있습니다",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2019/09/26/12/14/impossible-4505790_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/labeling/",
          is_manager_only: false
        },
        {
          title: "1차 검수 작업하기",
          postUrl: "/ipixel/inspection",
          description: "이미지를 검수할 수 있습니다",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2016/09/08/22/43/books-1655783_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/inspection",
          is_manager_only: true
        },
        {
          title: "2차 검수 작업하기",
          postUrl: "/ipixel/inspection/detail/list/",
          description: "이미지를 검수할 수 있습니다",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2014/07/11/14/49/cancer-389921_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/inspection/detail/list/",
          is_manager_only: true
        },
        {
          title: "반려 및 작업불가 이미지",
          postUrl: "/ipixel/return/list/",
          description: "반려 및 작업불가 이미지",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2017/08/30/07/52/money-2696219_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/return/list/",
          is_manager_only: true
        },
      ],
      datas2: [
        {
          title: "이미지 업로드하기",
          postUrl: "/ipixel/ipload/",
          description: "콜라캔, 스프라이트, 손 등의 사진과 그림을 업로드 할 수 있습니다.",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2017/07/03/11/10/ebook-2467267_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/upload/",
          is_manager_only: false
        },
        {
          title: "이미지 레이블링 작업하기",
          postUrl: "/ipixel/labeling/",
          description: "이미지를 자를 수 있습니다.",
          avatarImgSrc: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
          authorName: "eddhall0821",
          profileHref: "profile/eddhall0821/",
          date: "2019-08-12",
          imgSrc: "https://cdn.pixabay.com/photo/2019/09/26/12/14/impossible-4505790_1280.jpg",
          imgAlt: "pixel",
          iconName: "heart",
          iconHref: "#",
          postHref: "/ipixel/labeling/",
          is_manager_only: false
        },
        // {
        //     link: "/ipixel/return/list",
        //     title: "반려 이미지",
        //     description: "반려된 이미지.",
        //     cnt: "68",
        //     totalCnt: "1000",
        //     earn: "6290",
        //     is_manager_only: false
        // },
      ]
    }
  }
  componentDidMount() {
  }
  render() {
    return (
      <Page.Content title="작업 목록">
        <Grid.Row cards deck>
          {
          this.state.datas.map((data) => (
            (this.props.auth.user.is_manager||!data.is_manager_only)&&
              <Grid.Col sm={6} xl={3}>
                <BlogCard
                  title={data.title}
                  postUrl={data.postUrl}
                  description={data.description}
                  avatarImgSrc={data.avatarImgSrc}
                  authorName={data.authorName}
                  profileHref={data.profileHref}
                  date={data.date}
                  imgSrc={data.imgSrc}
                  imgAlt={data.imgAlt}
                  postHref={data.postHref}
                />
              </Grid.Col>
            ))
          }
        </Grid.Row>
      </Page.Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    list: state.list
  }
};

const mapDispatchToProps = dispatch => {
  return {
    get_images_for_inspection_detail: (image_id) => {
      return dispatch(projects.get_images_for_inspection_detail(image_id))
    },
    change_images_for_inspection_detail: (data) => {
      return dispatch(projects.change_images_for_inspection_detail(data))
    },
    change_survey_for_inspection_detail: (data) => {
      return dispatch(projects.change_survey_for_inspection_detail(data))
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuSelect_ipixel);