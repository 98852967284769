import * as React from "react";
import { Button } from "tabler-react"
import { connect } from "react-redux";
import { auth } from "../../actions";
import './AboutUS.css';
class AboutUS extends React.Component {

  render() {
  document.body.style = 'background: black;';
    return (
      <div style={{color:"green", textAlign:"center", verticalAlign:"middle", fontFamily:"Agency FB"}} >
        <div style={{fontSize:"100px"}}>　</div>
        <div style={{fontSize:"40px", fontWeight:"Bold"}}>
            PRESENT
        </div>
        <div>　</div>
        <div style={{fontSize:"20px"}}>
            DATA REPUBLIC provides raw data generation, data labeling.<br/>
            and data pre/post-processing services for AI companies
        </div>
        <div>　</div>
        <div>　</div>
        <div>　--------------------------------------------------------------------------------------　</div>
        <div>　</div>
        <div>　</div>
        <div style={{fontSize:"40px", fontWeight:"Bold"}}>
            FUTURE
        </div>
        <div>　</div>
        <div style={{fontSize:"20px"}}>
            We will create an AGI(Artificial General Intelligence).
            <br/>
            <br/>
            Our MOTHER will be presented at the end of 2020 3Q.
            <br/>
            <br/>
            Stay tuned
        </div>
        <div>　</div>
        <div>　</div>
        <div>　</div>
        <div>　</div>
        <Button style={{color:"green !important"}} RootComponent="a" href="/">Login</Button>
      </div>
    )
  }
}

export default connect()(AboutUS);