// 데이터 레이블링 프로젝트 생성 및 수정
import PUBLIC_URL from "../settings";


// 이미지 데이터 레이블링 대분류 프로젝트 생성하는 함수
export const create_image_labeling_main_category = (title, description) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ title, description });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/main-category/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'login required') {
                        dispatch({ type: 'LOGIN_REQUIRED' });
                        alert(res.data.msg);
                        throw res.data;
                    } else if (res.data.msg === 'field cannot be blank') {
                        alert(res.data.msg);
                        return res.data;
                    } else if (res.data.msg === 'success') {
                        dispatch({ type: 'CLOSE_MODAL' });
                        alert(res.data.msg);
                        window.location.reload();
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    throw res.data;
                }
            })
    }
};


export const create_image_labeling_sub_category = (title, description, password, serverIdArray, fileArray, main_category, template, points_per_label) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({
            title,
            description,
            password,
            serverIdArray,
            fileArray,
            main_category,
            template,
            points_per_label
        });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/sub-category/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'login required') {
                        dispatch({ type: 'LOGIN_REQUIRED' });
                        alert(res.data.msg);
                        throw res.data;
                    } else if (res.data.msg === 'field cannot be blank') {
                        alert(res.data.msg);
                        return res.data;
                    } else if (res.data.msg === 'success') {
                        dispatch({ type: 'CLOSE_MODAL' });
                        alert(res.data.msg);
                        window.location.reload();
                        return res.data;
                    } else if (res.data.msg === 'Not enough points') {
                        dispatch({ type: 'CLOSE_MODAL' });
                        alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    throw res.data;
                }
            })
    }
};

export const apply_project = (apply_for, project_id, password) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ apply_for, project_id, password });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/apply/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'login required') {
                        dispatch({ type: 'LOGIN_REQUIRED' });
                        alert(res.data.msg);
                        throw res.data;
                    } else if (res.data.msg === 'field cannot be blank') {
                        alert(res.data.msg);
                        return res.data;
                    } else if (res.data.msg === 'success') {
                        dispatch({ type: 'CLOSE_MODAL' });
                        alert(res.data.msg);
                        window.location.reload();
                        return res.data;
                    } else if (res.data.msg === 'incorrect password') {
                        alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    dispatch({ type: 'LOGIN_REQUIRED' });
                    alert("login required");
                    throw res.data;
                }
            })
    }
};

export const create_template = (template_title, labeling_array, user_id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ template_title, labeling_array, user_id });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/template/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.message === 'success') {
                        alert('success')
                    }
                } else {
                    dispatch({ type: 'LOGIN_REQUIRED' });
                    alert("login required");
                    throw res.data;
                }
            })
    }
};

export const submit_labeled_data = (image_pk, answer) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ image_pk, answer });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/submit/labeled/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.message === 'success') {
                        alert('success')
                    }
                } else {
                    dispatch({ type: 'LOGIN_REQUIRED' });
                    alert("login required");
                    throw res.data;
                }
            })
    }
};


// 이미지 데이터 레이블링 대분류 프로젝트 목록을 불러오는 함수
// 서버에서 permissions.AllowAny로 토큰값이 필요없음

export const get_image_labeling_main_category = (main_id) => {
    if (main_id) {
        return (dispatch, getState) => {
            let headers = { "Content-Type": "application/json" };
            return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/main-category/" + main_id + "/", { headers, })
                .then(res => res.json())
        }
    } else {
        return (dispatch, getState) => {
            let headers = { "Content-Type": "application/json" };
            return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/main-category/", { headers, })
                .then(res => res.json())
        }
    }
};

export const get_image_labeling_sub_category = (main_id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/sub-category/?main_id=" + main_id, { headers, })
            .then(res => res.json())
    }
};

export const get_image_labeling_sub_category_detail = (sub_id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/sub-category/" + sub_id + "/", { headers, })
            .then(res => res.json())
    }
};


export const get_start_image_labeling = (query) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/startproject/" + query, { headers, })
            .then(res => res.json())
    }
};

export const get_image_labeling_template = (query) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/template/" + query, { headers, })
            .then(res => res.json())
    }
};


export const get_completed_image_labels = (sub_id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/image-works/?sub_category_id=" + sub_id, { headers, })
            .then(res => res.json())
    }
};


export const get_completed_image_labels_answers = (image_id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/image-works-sub/?image_id=" + image_id, { headers, })
            .then(res => res.json())
    }
};

export const get_random_image_labeling = (inspection) => {
  let query = "";
  if (inspection) {
      query = "?inspection=true"
  } else {
      query = "?inspection=false"
  }
  return (dispatch, getState) => {
      let headers = { "Content-Type": "application/json" };
      let { token } = getState().auth;
      if (token) {
          headers["Authorization"] = `JWT ${token}`;
      }
      return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/revision-cate/" + query, { headers, })
          .then(res=>{
              if(res.status<500){
                  return res.json()
              }else{
                  return {status : "error"}
              }
          })
  }
};

export const get_random_letter_labeling = (inspection) => {
    let query = "";
    if (inspection) {
        query = "?inspection=true"
    } else {
        query = "?inspection=false"
    }
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/random-one-word/" + query, { headers, })
            .then(res=>{
                if(res.status<500){
                    return res.json()
                }else{
                    return {status : "error"}
                }
            })
    }
};
export const get_previous_letter_labeling = (id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let query = "?previous_work=" + id;
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/previous-inspection/" + query, { headers, })
            .then(res => res.json())
    }
};
export const submit_labeled_word_data = (image_pk, answer) => {
    return (dispatch, getState) => {
        console.log(answer)

        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ image_pk, answer });
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/random-one-word/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
        //            .then(res => {
        //                if (res.status === 200) {
        //                    if (res.message === 'success'){
        //                        alert('success')
        //                    }
        //                } else {
        //                    dispatch({type: 'LOGIN_REQUIRED'});
        //                    alert("login required");
        //                    throw res.data;
        //                }
        //            })
    }
};


export const get_completed_letter_labels_answers = (image_id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-letter/?image_id=" + image_id, { headers, })
            .then(res => res.json())
    }
};
//처번째 이미지 검수된 데이터의 id를 넘겨줌
export const submit_first_inspection_data = (id) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify(id);
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        // return console.log(id)
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-images/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
    }
};
export const submit_inspection_data = (image_pk, answer) => {
    return (dispatch, getState) => {
        console.log(answer)

        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ image_pk, answer });
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-change/", { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
        //            .then(res => {
        //                if (res.status === 200) {
        //                    if (res.message === 'success'){
        //                        alert('success')
        //                    }
        //                } else {
        //                    dispatch({type: 'LOGIN_REQUIRED'});
        //                    alert("login required");
        //                    throw res.data;
        //                }
        //            })
    }
};

export const get_object_type = () => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/object-type/", { headers, })
            .then(res => res.json())
    }
};


export const get_image_type = () => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/image-type/", { headers, })
            .then(res => res.json())
    }
};
export const get_payment = () => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/payment/", { headers, })
            .then(res => res.json())
    }
};
export const post_payment = (username) => {
  return (dispatch, getState) => {
      let query = "?username=" + username;
      let headers = { "Content-Type": "application/json" };
      return fetch(PUBLIC_URL + "/api/projects/payment-each/" + query, { headers, })
          .then(res => res.json())
  }
};

export const get_not_valid_image = (project, type) => {
    return (dispatch, getState) => {
        let query = "?project=" + project + "&type=" + type;
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/image-not-valid/" + query, { headers, })
            .then(res => res.json())
    }
};

export const get_image_upload_count = (object_type) => {
    return (dispatch, getState) => {
        let query = "?object_type=" + object_type;
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/image-count/" + query, { headers, })
            .then(res => res.json())
    }
};

export const get_image_multiple_choice_project = (object_type) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let query = "?object_type=" + object_type;
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/multiple-choice-project/" + query, { headers, })
            .then(res => res.json())
    }
};


export const create_image_multiple_choice_project = (data) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ data });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/multiple-choice-project/", {
            headers,
            body,
            method: "POST"
        })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'success') {
                        alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    throw res.data;
                }
            })
    }
};


export const submit_image_multiple_choice_project = (data) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ data });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/submit/multiple-choice-project/", {
            headers,
            body,
            method: "POST"
        })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    return res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'success') {
                        alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    return res.data;
                }
            })
    }
};

export const get_images_for_inspection = (filter, category, page, is_valid_image) => {
    let query = `?filter=${filter}&category=${category}&page=${page}&is_valid_image=${is_valid_image}`
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-images/" + query, { headers, })
            .then(res => res.json())
    }
};


export const get_images_for_inspection_detail = (image_id) => {
    let query = "?image_id=" + image_id;
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-images/detail/" + query, { headers, })
            .then(res => res.json())
    }
};
export const get_return_images_for_detail = (label_id) => {
    let query = "?label_id=" + label_id;
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-images/detail/" + query, { headers, })
            .then(res => res.json())
    }
};

export const change_images_for_inspection_detail = (data) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ data });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-images/detail/", {
            headers,
            body,
            method: "POST"
        })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'success') {
                        // alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    throw res.data;
                }
            })
    }
};

export const change_survey_for_inspection_detail = (data) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ data });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-images/detail/", {
            headers,
            body,
            method: "POST"
        })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    alert("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'success') {
                        // alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    throw res.data;
                }
            })
    }
};

export const inspect_confirm = (data) => {
    return (dispatch, getState) => {
        let headers = { "Content-Type": "application/json" };
        let body = JSON.stringify({ data });
        let { token } = getState().auth;

        if (token) {
            headers["Authorization"] = `JWT ${token}`;
        }

        return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/inspect-confirm/", {
            headers,
            body,
            method: "POST"
        })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.msg === 'success') {
                        // alert(res.data.msg);
                        return res.data;
                    }
                } else {
                    console.log(res.data);
                    return res.data;
                }
            })
    }
};

export const category_inspect_confirm = (data) => {
  return (dispatch, getState) => {
      let headers = { "Content-Type": "application/json" };
      let body = JSON.stringify({ data });
      let { token } = getState().auth;

      if (token) {
          headers["Authorization"] = `JWT ${token}`;
      }

      return fetch(PUBLIC_URL + "/api/projects/data-labeling/image/revision-cate-confirm/", {
          headers,
          body,
          method: "POST"
      })
          .then(res => {
              if (res.status < 500) {
                  return res.json().then(data => {
                      return { status: res.status, data };
                  })
              } else {
                  throw res;
              }
          })
          .then(res => {
              if (res.status === 200) {
                  if (res.data.msg === 'success') {
                      // alert(res.data.msg);
                      return res.data;
                  }
              } else {
                  console.log(res.data);
                  return res.data;
              }
          })
  }
};