import * as React from "react";
import {Form, Card} from "tabler-react"
import PostCode from "./PostCode"
import {auth} from "../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

function checkEmail(str) {

    let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (regExp.test(str)) {
        return true
    } else return false;

}


class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            name: "",
            email: "",
            phone: "",
            gender: "",
            address1: "",
            address2: "",
            foreign_language: [],
            confirmPassword: "-",
            usernameValid: false,
            english: false,
            chinese: false,
            birth: new Date().getUTCFullYear().toString() + '-' + (new Date().getUTCMonth() + 1).toString() + '-' + new Date().getUTCDate().toString(),
            japanese: false
        };
        this.handleForeignLanguageInput = this.handleForeignLanguageInput.bind(this);
    }

    checkRegisterInfo = () => {
        // 아이디 중복 아이디인지 아닌지 확인한다.
        if (this.state.usernameValid === false) {
            alert('아이디를 확인해주세요.');
            return false
        }

        // 비번 1,2 같은 지 확인
        // 비번 6자이상인지 아닌지 확인
        if (this.state.password !== this.state.confirmPassword) {
            alert('비밀번호가 일치하지 않습니다.');
            return false
        } else {
            if (this.state.password.length > 5) {

            } else {
                alert('비밀번호 길이는 최소 6글자 입니다.');
                return false
            }
        }

        // 이름 입력여부
        if (this.state.name.length === 0) {
            alert('이름을 입력해주세요');
            return false
        }

        // 이메일 입력 여부
        if (this.state.email.length === 0) {
            alert('이메일을 입력해주세요');
            return false
        } else {
            if (!checkEmail(this.state.email)) {
                console.log(this.state.email, checkEmail(this.state.email))
                alert('올바른 이메일 주소를 입력해주세요');
                return false
            }
        }

        // 연락처 입력 여부
        if (this.state.phone.length === 0) {
            alert('연락처를 입력해주세요');
            return false
        }

        // 성별 입력 여부
        if (this.state.gender === "") {
            alert('성별을 입력해주세요');
            return false
        }

        // 주소 입력
        if (this.state.address1.length === 0 || this.state.address2.length === 0) {
            alert('주소를 입력해주세요');
            return false
        }
        let foreign_language_boolean = [this.state.english, this.state.chinese, this.state.japanese];
        let foreign_language_field = ['영어', '중국어', '일본어'];
        let foreign_language = [];
        foreign_language_boolean.map((item, id) => {
                if (item) {
                    foreign_language.push(foreign_language_field[id])
                }
            }
        );
        this.setState({foreign_language: foreign_language});
        return true
    };

    onSubmit = async () => {

        let infoValid = await this.checkRegisterInfo();
        if (infoValid) {
            let info = {
                username: this.state.username,
                password: this.state.password,
                name: this.state.name,
                birth: this.state.birth,
                email: this.state.email,
                phone: this.state.phone,
                gender: this.state.gender,
                address1: this.state.address1,
                address2: this.state.address2,
                foreign_language: this.state.foreign_language

            };
            await this.props.register(info);
            await this.props.login(this.state.username, this.state.password);
            alert('가입 완료');
            window.location = '/'
        } else {
            // alert('가입 불가')
        }

    };

    handleForeignLanguageInput(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    checkUsername = async () => {
        let username = this.state.username;
        if (username.length > 0) {
            await this.props.check_username_valid(username).then(res => {
                if (res.msg === 'valid') {
                    this.setState({usernameValid: true});
                    alert('사용 가능한 아이디 입니다.')
                } else {
                    alert('이미 사용중인 아이디 입니다.')
                }
            })
        } else {
            alert('아이디를 입력해주세요.')
        }
    };

    getAddress = (address) => {
        this.setState({
            address1: address
        })
    };

    render() {
        return (

            <div style={{maxWidth: 800, margin: 'auto', marginTop: 10}}>
                <div style={{margin: 20, textAlign: 'center'}}>
                    <Link to={'/'}>
                        <img className={'h-6'}
                             src='https://cdn.iconscout.com/icon/premium/png-256-thumb/ai-75-806497.png'/>
                    </Link>
                </div>
                <Card
                    title="계정을 생성해 보세요."
                >
                    <div style={{padding: 20}}>
                        <Form.Group>
                            <Form.Input
                                onChange={e => this.setState({username: e.target.value})}
                                name="username"
                                placeholder="아이디"
                            />
                            <button className={'btn btn-primary mt-3 w-100'} onClick={this.checkUsername}>중복확인
                            </button>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                onChange={e => this.setState({password: e.target.value})}
                                name="password"
                                type="password"
                                placeholder="비밀번호"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                tick={this.state.password === this.state.confirmPassword}
                                cross={this.state.password !== this.state.confirmPassword}
                                onChange={e => this.setState({confirmPassword: e.target.value})}
                                type="password"
                                placeholder="비밀번호 확인"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                onChange={e => this.setState({name: e.target.value})}
                                name="name"
                                placeholder="이름"
                            />
                        </Form.Group>
                        <Form.Group label="생년월일을 선택하세요">
                            <Form.DatePicker
                                defaultDate={new Date()}
                                onChange={e => this.setState({birth: e.getUTCFullYear().toString() + '-' + (e.getUTCMonth() + 1).toString() + '-' + e.getUTCDate().toString()})}
                                format="yyyy/mm/dd"
                                maxYear={2019}
                                minYear={1900}
                                monthLabels={[
                                    '1월',
                                    '2월',
                                    '3월',
                                    '4월',
                                    '5월',
                                    '6월',
                                    '7월',
                                    '8월',
                                    '9월',
                                    '10월',
                                    '11월',
                                    '12월'
                                ]}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                onChange={e => this.setState({email: e.target.value})}
                                name="email"
                                type="email"
                                placeholder="이메일"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                onChange={e => this.setState({phone: e.target.value})}
                                name="phone"
                                placeholder="연락처"
                            />
                        </Form.Group>
                        <Form.Group label="성별">
                            <Form.Radio
                                onChange={e => this.setState({gender: e.target.value})}
                                isInline
                                label="남자"
                                name="gender"
                                value="male"
                            />
                            <Form.Radio
                                onChange={e => this.setState({gender: e.target.value})}
                                isInline
                                label="여자"
                                name="gender"
                                value="female"
                            />
                        </Form.Group>
                        <Form.Group>
                            <PostCode getAddress={this.getAddress}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                readOnly
                                name="address1"
                                value={this.state.address1}
                                placeholder="주소"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                onChange={e => this.setState({address2: e.target.value})}
                                name="address2"
                                placeholder="상세주소"
                            />
                        </Form.Group>
                        <form className={'form-group'}>
                            <label>영어</label>
                            <input className={'m-2'} name={'english'} type={'checkbox'} checked={this.state.english}
                                   onChange={this.handleForeignLanguageInput}/>
                            <label>중국어</label>
                            <input className={'m-2'} name={'chinese'} type={'checkbox'} checked={this.state.chinese}
                                   onChange={this.handleForeignLanguageInput}/>
                            <label>일본어</label>
                            <input className={'m-2'} name={'japanese'} type={'checkbox'} checked={this.state.japanese}
                                   onChange={this.handleForeignLanguageInput}/>

                        </form>
                    </div>
                </Card>
                <button className={'btn btn-primary w-100'} style={{marginBottom: '12rem'}}
                        onClick={this.onSubmit}>계정
                    생성
                </button>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        check_username_valid: (username) => {
            return dispatch(auth.check_username_valid(username));
        },
        register: (info) => {
            return dispatch(auth.register(info));
        },

        login: (username, password) => {
            return dispatch(auth.login(username, password));
        },

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);