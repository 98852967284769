import React from 'react';
import { connect } from "react-redux";
import { projects } from "../../actions";
import Cropper from "react-cropper";
import ScrollArea from "react-scrollbar";
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Header, Button, Grid} from "tabler-react"
import { SelectBox } from "../../components/SelectBox"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class CategoryInspection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      img: '',
      cropResult: '',
      letter_data: [],
      work_sub: '',
      index: 0,
      selected: '',
      modalIsOpen: false,
      reasonSelect: '',
      reasonText: '',
      labeled_by: {
      },
      previous_work: 0,
      arrAPI: [],
      view: false,
      letter_del: [],
      objectType: [
        {title : "발신기본주소"},
        {title : "발신상세주소"},
        {title : "발신상호명"},
        {title : "발신우편번호"},
        {title : "발신이름"},
        {title : "발신전화번호"},
        {title : "수신기본주소"},
        {title : "수신상세주소"},
        {title : "수신상호명"},
        {title : "수신우편번호"},
        {title : "수신이름"},
        {title : "수신전화번호"}      
      ]
    }
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this._handleOptionChange = this._handleOptionChange.bind(this);
    this._handleOptionChange2 = this._handleOptionChange2.bind(this);
  }

  componentDidMount() {
    this.callAPI()
  }
  callAPI = async () => {
    let inspection = true;
    await this.props.get_random_image_labeling(inspection).then(response => {
      console.log(response)
      if (response.status === "error") {
        console.log("ERR")
      } else {
        this.setState({
          labeled_by: response.labeled_by,
          letter_data: response.letter_data,
          img: response.img,
          work_sub_id: response.work_sub_id,
          work_sub: response.work_sub,
          previous_work: response.previous_work,
          view: true
        })
      }
    }).then(()=> {
      setTimeout(()=>this._clickAll(),1000)
    })
  }
  refresh = () => {
    const { arrAPI } = this.state
    this.setState({
      labeled_by: arrAPI[0].labeled_by,
      letter_data: arrAPI[0].letter_data,
      img: arrAPI[0].img,
      work_sub_id: arrAPI[0].work_sub_id,
      work_sub: arrAPI[0].work_sub,
      previous_work: arrAPI[0].previous_work,
    })
    this.callAPI(arrAPI.length)
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  _crop() {

  }

  _click(result, index) {
    this.setState({
      index: index,
      selected: result  
    })
    this.refs[index].setData(
      result
    )
    this.refs.cropper.setData(
      result
    );
  }
  _clickAll = () => {
    this.state.letter_data.map((letter, index) =>
      this._click(letter, index))
  }
  _handleOptionChange(changeEvent) {
    this.setState({
      reasonSelect: changeEvent.target.value
    })
  }

  _handleOptionChange2(changeEvent) {
    this.setState({
      reasonText: changeEvent.target.value
    })
  }


  _handleInspect = async (type) =>{
    const { arrAPI } = this.state
    let letter_ids = [];
    this.state.letter_data.map((letter) =>
      letter_ids.push(letter.id)
    );
    let data;
    if (type === 'wrong_image') {

      data = {
        type: 'wrong_image',
        letter_ids: letter_ids
      };
      // this.arrShift(arrAPI)
    } else if (type === 'modify') {
      letter_ids = [this.state.selected.id, this.refs.cropper.getData()];
      data = {
        type: 'modify',
        letter_ids: letter_ids
      };
      alert('수정됨')
    } else if (type === 'confirm') {
      data = {
        type: 'confirm',
        letter_ids: letter_ids,
        letter_del: this.state.letter_del
      };
      // this.arrShift(arrAPI)
    } else if (type === 'not_workable2') {
      this.closeModal();
      data = {
        type: 'not_workable',
        letter_ids: letter_ids,
        reasonSelect: this.state.reasonSelect,
        reasonText: this.state.reasonText
      };
      this.setState({ reasonText: "", reasonSelect: "" });
      // this.arrShift(arrAPI)
    } else {
      alert("error")
    }
    console.log(this.state.letter_data)
    await this.props.category_inspect_confirm(this.state.letter_data[0])
    await this.callAPI()
  }
  arrShift(arr) {
    if (arr.length === 1) {
      arr.shift()
      this.setState({
        view: false
      })
    } else {
      arr.shift()
      this.refresh()
    }
  }
  _handleDelete(index) {
    const { letter_data } = this.state
    letter_data[index].deleted ? letter_data[index].deleted = false : letter_data[index].deleted = true
    this.setState({
      letter_data
    })
    this._clickAll()
  }

  _handleOrgErr(index) {
    const { letter_data } = this.state
    letter_data[index].org_err ? letter_data[index].org_err = false : letter_data[index].org_err = true
    this.setState({
      letter_data
    })
    this._clickAll()
  }
  get_object_type = (data) =>{
    let {letter_data} = this.state
    letter_data[0].labeling = data
    this.setState({
      letter_data
    })
  }
  previous_page = (previous_work) => {
    this.props.get_previous_letter_labeling(previous_work).then(response =>
      this.setState({
        labeled_by: response.labeled_by,
        letter_data: response.letter_data,
        img: response.img,
        work_sub_id: response.work_sub_id,
        work_sub: response.work_sub,
        previous_work: response.previous_work
      })
    )
  }

  render() {
    let itemElements =
      this.state.letter_data.map((letter, index) =>(
        <Grid.Row className="mb-5">
          <input style={{ width: 200 }}
            className="ml-3"
            value={letter.answer}
            onChange={e => {
              const { letter_data } = this.state
              letter_data[index].answer = e.target.value
              this.setState({
                letter_data
              })
            }} />
          {/* <Button onClick={e=>console.log(this.state)}>수정</Button> */}
          <Button onClick={() => this._click(letter, index)}
            color="info" size="lg" className="ml-1">{letter.answer}</Button>
          <Button onClick={() => this._handleDelete(index)}
            color={letter.deleted ? "danger" : "warning"} className="ml-1">삭제</Button>
          <Button onClick={() => this._handleOrgErr(index)}
            color={letter.org_err ? "danger" : "warning"} className="ml-1 mr-1">원본문제</Button>
          <Cropper
            style={{ display: "none" }}
            src={this.state.img}
            ref={index}
            preview={`.preview${index}`}
          />
          <div className={`preview${index}`}
            style={
              isMobile ?
                { width: 250, height: 100, overflow: "hidden" } :
                { width: 300, height: 100, overflow: "hidden", display: "block" }}></div>
            <SelectBox
              objectOption={this.state.letter_data[0].labeling}
              sendData={this.get_object_type}
              data={this.state.objectType} />
        </Grid.Row>
        )
      )
    const { labeled_by } = this.state
    return (
      this.state.view ?
        <KeyboardEventHandler
          handleKeys={['right']}
          onKeyEvent={(key, e) => alert(`do something upon keydown event of ${key}`)}>
          <KeyboardEventHandler
            handleKeys={['q']}
            onKeyEvent={(e) => this._clickAll()}>
          </KeyboardEventHandler>
          <KeyboardEventHandler
            handleKeys={['w']}
            onKeyEvent={(e) => this._handleInspect('confirm')}>
          </KeyboardEventHandler>
          <div style={isMobile ? { marginLeft: 20 } : { marginLeft: 100 }} className='mt-5'>
            <Header.H2>
              {`작업자 : ${labeled_by.user}
                    총 작업수 : ${labeled_by.user_total_works}
                    반려 작업수 : ${labeled_by.user_not_workable}`}
            </Header.H2>
            <Grid.Row>
              <Grid.Col>
              <Cropper
                dragMode={'move'}
                autoCropArea={0.001}
                preview=".preview"
                className="img-container"
                ref='cropper'
                src={this.state.img}
                style={{ height: '50%', width: '100%' }}
                crop={this._crop.bind(this)} />
              <div className={'column ml-3 mr-3'}
                style={{ alignContent: 'flex-end', maxWidth: 60, alignSelf: 'flex-end' }}>
                <button className="btn btn-success" onClick={() => this.refs.cropper.zoom(0.1)}>+</button>
                <button className="btn btn-success mt-3 mb-3" onClick={() => this.refs.cropper.zoom(-0.1)}>-
                </button>
              </div>
              </Grid.Col>
              <Grid.Col>
                <div className="preview"
                  style={
                    isMobile ?
                      { width: 250, height: 100, overflow: "hidden" } :
                      { width: 300, height: 100, overflow: "hidden", marginLeft: 60 }}></div>
                <Button onClick={e => { this._clickAll() }}>전체 적용</Button>
                {this.refs.cropper &&
                <Button.List>
                    <Button onClick={() => {
                      let {letter_data, index} = this.state
                      letter_data[index].width = this.refs.cropper.getData().width
                      letter_data[index].height = this.refs.cropper.getData().height
                      letter_data[index].x = this.refs.cropper.getData().x
                      letter_data[index].y = this.refs.cropper.getData().y
                      this.setState({
                        letter_data
                      })
                      this._clickAll()
                    }}
                    color="submit">수정
                    </Button>
                    <Button onClick={() => this._handleInspect('confirm')}
                      color="success">완료
                    </Button>
                  </Button.List>
                  }
                <ScrollArea
                  className={"w-100"}
                  horizontalScrollbarStyle={{ borderRadius: 5 }}
                  smoothScrolling={true}
                  minScrollSize={40}
                  swapWheelAxes={true}>
                  {itemElements}
                </ScrollArea>
              </Grid.Col>
            </Grid.Row>
            <div>
              <div className={'row mt-5'}>
                <button onClick={() => this.refs.cropper.setDragMode('move')}
                  className="btn btn-success mr-3">
                  <img style={{ width: 15 }}
                    src={'https://cdn2.iconfinder.com/data/icons/music-player-icons-filled/55/Expand_2-512.png'} />
                </button>
                <button onClick={() => this.refs.cropper.setDragMode('crop')} className="btn btn-success">
                  <img
                    style={{ width: 15 }}
                    src={'https://cdn2.iconfinder.com/data/icons/social-messaging-productivity-1/128/photo-crop-512.png'} />
                </button>
              </div>
              <Button.List align="center">
                <Button onClick={() => this._handleInspect('confirm')}
                  color="success">완료
                            </Button>
              </Button.List>
              <button onClick={e => this.previous_page(this.state.previous_work)}
                //style={{width: '15%'}}
                className="btn mr-3">이전 페이지
                            </button>
            </div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >

              <div className={'row'}>
                <h2 ref={subtitle => this.subtitle = subtitle}>반려 사유</h2>
                <button className={'btn btn-warning ml-5'} onClick={this.closeModal}>x</button>
              </div>
              <form>
                <div className="radio">
                  <label>
                    <input type="radio" value="1"
                      checked={this.state.reasonSelect === '1'}
                      onChange={this._handleOptionChange} />
                    영역을 잘못 지정함
                                </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" value="2"
                      checked={this.state.reasonSelect === '2'}
                      onChange={this._handleOptionChange} />
                    글자를 잘못 입력함
                                </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" value="3"
                      checked={this.state.reasonSelect === '3'}
                      onChange={this._handleOptionChange} />
                    기타
                                </label>
                </div>
                {
                  this.state.reasonSelect === '3' ?
                    <div>
                      <input type="text" className={'form-control'}
                        onChange={this._handleOptionChange2} />
                    </div> : null
                }
              </form>
              <button onClick={() => this._handleInspect('not_workable2')}
                className="btn btn-danger mr-3">반려
                        </button>
            </Modal>
          </div>
        </KeyboardEventHandler> : <div>LOADING..</div>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submit_labeled_word_data: (image_pk, answer) => {
      return dispatch(projects.submit_labeled_word_data(image_pk, answer))
    },
    get_random_image_labeling: (inspection) => {
      return dispatch(projects.get_random_image_labeling(inspection))
    },
    get_previous_letter_labeling: (previous_work) => {
      return dispatch(projects.get_previous_letter_labeling(previous_work))
    },
    category_inspect_confirm: (data) => {
      return dispatch(projects.category_inspect_confirm(data))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryInspection)