import React from 'react';
import {Form} from "tabler-react";

export const SelectBox = props =>{
    let data ="DEFAULT"
    if(props.objectOption)
      data=props.objectOption
        const handleChange=(event)=>{
        data = event.target.value
        props.sendData(data);
    }
    return(
      //데이터를 받아와서 map 하는데 x가 hide가 아닐경우에만 보여준다. => !x.hide&&
        <div key={props.objectOption?props.objectOption:""}>
          <select onChange={e=>handleChange(e)} defaultValue={data} className="custom-select">
            <option disabled value={"DEFAULT"}>옵션을 선택해주세요.</option>
          {props.data.map((x,y) =>!x.hide&&<option key={y} value={x.title}>{x.title}</option>)}
          </select>
        </div>
    )
}

export const RadioBox = props =>{
  const question = props.questionAnswer
  const handleChange =(e, y)=>{
    question[y] = e.target.value
    props.sendData(question)
  }

    return(
      <div>
      {
         props.data.map((x,y) =>(
          <Form.Group label={x} key={y}>
          <Form.Radio
            isInline
            onChange ={e=>handleChange(e, y)} 
            label = "예"
            name = {x}
            value = "1"
          />
          <Form.Radio
            isInline
            onChange ={e=>handleChange(e, y)} 
            label = "아니오"
            name = {x}
            value = "0"
          />
          <Form.Radio
            isInline
            onChange ={e=>handleChange(e, y)} 
            label = "모름"
            name = {x}
            value = "-1"
          />
         </Form.Group>
         ))
      }
  </div>
  )
}